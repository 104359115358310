import { USER_UPDATE_INFO, USER_LOGIN_ERROR, USER_CLEAR_SESSION } from 'redux/actions/user/action_types';

const initialStore = {
  loaded: false,
  id: null,
  userId: null,
  name: null,
  roleName: null,
  permissions: [],
  errMsg: null
};

const updateUserInfo = (state, user) =>
  Object.assign({}, state, {
    loaded: true,
    id: user.id,
    userId: user.userId,
    name: user.name,
    roleName: user.roleName,
    permissions: user.permissions
  });

export default function userReducer(state = initialStore, action) {
  switch (action.type) {
    case USER_UPDATE_INFO:
      return updateUserInfo(state, action.payload);
    case USER_LOGIN_ERROR:
      return Object.assign({}, state, { errMsg: 'Invalid username/password.' });
    case USER_CLEAR_SESSION:
        return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
