// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button, Modal, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './login.module.less';

class Login extends Component {
  state = {
    modalVisible: false
  };

  onCancel = () => this.setState({ modalVisible: false });

  onLoginBtnClick = () => {
    this.props.history.push('/customer/login/');
  };

  onOk = () => {};

  onRegister = () => {
    this.props.history.push('/customer/my-account/register/');
    this.onCancel();
  };

  onForgetPassword = () => {
    this.props.history.push('/customer/forget-password/');
    this.onCancel();
  };

  render() {
    if (this.props.isUserLoggedIn) {
      return (
        <div>
          <button className={style.logoutBtn} onClick={this.props.logoutBtnClickHandler}>
            Logout <FontAwesomeIcon icon="sign-out-alt" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button className={style.loginBtn} onClick={this.onLoginBtnClick}>
            <FontAwesomeIcon icon="lock" /> Sign In
          </button>
          <button className={style.loginBtn} onClick={this.onRegister}>
            <FontAwesomeIcon icon="address-card" /> Register
          </button>
          <Modal
            title="Welcome back, please login"
            centered
            visible={this.state.modalVisible}
            footer={null}
            onCancel={this.onCancel}
          >
            <WrappedLoginForm
              onRegister={this.onRegister}
              onForgetPassword={this.onForgetPassword}
              loginBtnClickHandler={this.props.loginBtnClickHandler}
              userLoginErrMsg={this.props.userLoginErrMsg}
            />
          </Modal>
        </div>
      );
    }
  }
}

class LoginForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.loginBtnClickHandler(values.username, values.password);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {this.props.userLoginErrMsg !== null && (
          <Alert message="Error" description={this.props.userLoginErrMsg} type="error" showIcon />
        )}
        <Form.Item className={style.row}>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }]
          })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />)}
        </Form.Item>
        <Form.Item className={style.row}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item className={style.row}>
          <Button type="primary" htmlType="submit" block className="login-form-button">
            Log in
          </Button>
        </Form.Item>
        <Form.Item className={style.row}>
          <Button.Group>
            <Button type="primary" ghost onClick={this.props.onForgetPassword}>
              Forgot Password
            </Button>
            <Button type="primary" onClick={this.props.onRegister} ghost>
              Register now!
            </Button>
          </Button.Group>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

export default withRouter(Login);
