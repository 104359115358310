import {
  CUSTOMER_LOAD,
  CUSTOMER_LOADED,
  CUSTOMER_REGISTER,
  CUSTOMER_SUMBIT_OK,
  CUSTOMER_PASSWORD_RESET,
  CUSTOMER_PASSWORD_RESET_BY_CODE,
  CUSTOMER_FORGET_PASSWORD
} from './action_types';

const loadMyAccountDetails = () => ({
  type: CUSTOMER_LOAD,
  payload: {}
});

const myAccountDetailsLoaded = payload => ({
  type: CUSTOMER_LOADED,
  payload: payload
});

const resetPassword = payload => ({
  type: CUSTOMER_PASSWORD_RESET,
  payload: payload
});

const forgetPassword = payload => ({
  type: CUSTOMER_FORGET_PASSWORD,
  payload: payload
});

const resetPasswordByCode = payload => ({
  type: CUSTOMER_PASSWORD_RESET_BY_CODE,
  payload: payload
});

const trySaveCustomer = payload => ({
  type: CUSTOMER_REGISTER,
  payload: payload
});

const customerSavedOk = payload => ({
  type: CUSTOMER_SUMBIT_OK,
  payload: payload
});

export {
  loadMyAccountDetails,
  myAccountDetailsLoaded,
  trySaveCustomer,
  customerSavedOk,
  resetPassword,
  resetPasswordByCode,
  forgetPassword
};
