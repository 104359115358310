// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Row, Col, Input, Button, notification, Divider } from 'antd';
import { DisplayField, Spinner } from 'components';
import { withEventBus } from 'context/eventbus';
import * as customerActions from 'redux/actions/customer';
import * as customerSelectors from 'redux/selectors/customer';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/validationHelper';
import { CUSTOMER_SUMBIT_OK } from 'redux/actions/customer/action_types';
import styles from './resetPasswrod.module.less';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(CUSTOMER_SUMBIT_OK, () => {
        this.openNotificationWithIcon('success', 'Your password updated.');
      })
    );
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: 'Password changed Succesful',
      description: message
    });
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  resetPasswordHandler = value => {
    value.id = this.props.customer.id;
    this.props.resetPassword(value);
  };

  render() {
    let content;
    let myDetails = this.props.customer;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <div>
          <Row gutter={24}>
            <Col span={8}>
              <DisplayField label="Customer Id" value={myDetails.code} />
            </Col>
            <Col span={8}>
              <DisplayField label="Email" value={myDetails.email} />
            </Col>
            <Col span={8}>
              <DisplayField label="Status" value={myDetails.status.id} />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <DisplayField label="First Name" value={myDetails.firstName} />
            </Col>
            <Col span={8}>
              <DisplayField label="Middle Name" value={myDetails.middleName} />
            </Col>
            <Col span={8}>
              <DisplayField label="Last name" value={myDetails.lastName} />
            </Col>
          </Row>
          <Divider />
          <Row gutter={24}>
            <Col span={24}>
              <WrappedResetPasswordForm
                eventBusSubscribe={this.props.eventBusSubscribe}
                resetPassword={this.resetPasswordHandler}
              ></WrappedResetPasswordForm>
            </Col>
          </Row>
        </div>
      );
    }

    return <div>{content}</div>;
  }
}

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.resetPassword(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Password" className={styles.row}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please re-confirm your password!'
                  }
                ]
              })(<Input.Password placeholder="Password" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Confirm Password" className={styles.row}>
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Please re-confirm your password!'
                  },
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(<Input.Password placeholder="Confirm Password" />)}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedResetPasswordForm = Form.create({ name: 'reset_password' })(ResetPasswordForm);

const mapDispatchToProps = {
  resetPassword: customerActions.resetPassword
};

const mapStateToProps = (state, ownProps) => ({
  customer: customerSelectors.getCustomer(state),
  isLoaded: customerSelectors.isLoaded(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(withRouter(ResetPassword)));
