import { INVOICES_SEARCH, INVOICE_VIEW, INVOICE_VIEW_LOADED } from './action_types';

const searchInvoice = payload => ({
  type: INVOICES_SEARCH,
  payload: payload
});

const findOne = payload => ({
  type: INVOICE_VIEW,
  payload: payload
});

const foundOne = payload => ({
  type: INVOICE_VIEW_LOADED,
  payload: payload
});

export { searchInvoice, findOne, foundOne };
