import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Row, Col, Input, Button, Alert } from 'antd';
import { withEventBus } from 'context/eventbus';
import * as customerActions from 'redux/actions/customer';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/validationHelper';
import { CUSTOMER_SUMBIT_OK } from 'redux/actions/customer/action_types';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      status: null
    };
  }

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(CUSTOMER_SUMBIT_OK, action => {
        this.setState({
          status: action
        });
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  resetPasswordHandler = value => {
    this.props.forgetPassword(value);
  };

  render() {
    return (
      <div>
        {this.state.status !== null ? (
          <Row gutter={24}>
            <Col span={8}></Col>
            <Col span={8}>
              <Alert message="Password reset successful, Please check your email for instructions." type="success" />
            </Col>
            <Col span={8}></Col>
          </Row>
        ) : (
          <div></div>
        )}
        <Row gutter={24}>
          <Col span={24}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}></Col>
          <Col span={8}>
            <h2>Please enter your registerd email</h2>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <WrappedForgetPasswordForm
              eventBusSubscribe={this.props.eventBusSubscribe}
              resetPassword={this.resetPasswordHandler}
            ></WrappedForgetPasswordForm>
          </Col>
        </Row>
      </div>
    );
  }
}

class ForgetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      globalErrors: []
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.resetPassword(values.email);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Row gutter={24}>
          <Col span={4}></Col>
          <Col span={16}>
            {this.state.globalErrors.length > 0 && (
              <Alert
                type="error"
                showIcon
                description={
                  <ul>
                    {this.state.globalErrors.map((e, idx) => (
                      <li key={idx}>{e}</li>
                    ))}
                  </ul>
                }
              />
            )}
          </Col>
          <Col span={4}></Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item label="Registred Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your email!'
                  }
                ]
              })(<Input placeholder="Registred Email" />)}
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForgetPasswordForm = Form.create({ name: 'forget_password' })(ForgetPasswordForm);

const mapDispatchToProps = {
  forgetPassword: customerActions.forgetPassword
};

const mapStateToProps = (state, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(withRouter(ForgetPassword)));
