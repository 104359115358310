import { PAGE_LOADED } from 'redux/actions/page/action_types';

const initialStore = {
  page: {},
  isLoaded: false
};

const load = (state, payload) => {
  return Object.assign({}, state, {
    isLoaded: true,
    page: payload
  });
};

export default function order(state = initialStore, action) {
  switch (action.type) {
    case PAGE_LOADED:
      return load(state, action.payload);
    default:
      return state;
  }
}
