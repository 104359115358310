import { fetchApi } from 'Utils/utils';

let fetchProducts = function(filters) {
  return fetchApi.post('/api/v1/product/list/', filters);
};

let fetchLatestPhones = function(limit) {
  return fetchApi.get(`/api/v1/product/latestPhones/${limit}/`);
};

let fetchProductsPageCount = function(filters) {
  return fetchApi.post('/api/v1/product/list/count/', filters);
};

let fetchProductFilters = function(filters) {
  return fetchApi.post('/api/v1/product/list/filters/', filters);
};

let fetchProduct = function(productId, variantId) {
  return fetchApi.get(`/api/v1/product/${productId}/variant/${variantId}/`);
};

let fetchVariantImage = function(payload) {
  return fetchApi.get(`/api/v1/product/${payload.productId}/variant/${payload.variantId}/image/`);
};

export {
  fetchProducts,
  fetchProductFilters,
  fetchProductsPageCount,
  fetchProduct,
  fetchLatestPhones,
  fetchVariantImage
};
