import { call, put, takeLatest, all } from 'redux-saga/effects';
import { SUBCATEGORIES_LOAD, SUBCATEGORIES_LOADED } from '../actions/category/action_types';
import * as categoryService from '../services/category';

function* fetchSubCategories() {
  yield takeLatest(SUBCATEGORIES_LOAD, function*(action) {
    let subCategories = yield call(categoryService.fetchSubCategories, action.payload.ids);
    yield put({
      type: SUBCATEGORIES_LOADED,
      payload: subCategories
    });
  });
}

export default function* categorySagas() {
  yield all([fetchSubCategories()]);
}
