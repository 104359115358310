import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faLock,
  faSearch,
  faEnvelopeOpen,
  faShoppingCart,
  faEnvelope,
  faAngleDoubleRight,
  faMapMarkedAlt,
  faPhone,
  faSignOutAlt,
  faSyncAlt,
  faCheckSquare,
  faFilter,
  faEdit,
  faSpinner,
  faPrint,
  faExclamationCircle,
  faTrash,
  faAddressCard
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faUser,
  faLock,
  faSearch,
  faEnvelopeOpen,
  faShoppingCart,
  faEnvelope,
  faAngleDoubleRight,
  faMapMarkedAlt,
  faPhone,
  faSignOutAlt,
  faSyncAlt,
  faCheckSquare,
  faFilter,
  faEdit,
  faSpinner,
  faPrint,
  faExclamationCircle,
  faTrash,
  faAddressCard
);
