import React, { Component } from 'react';
import { Form, Row, Col, InputNumber, Select } from 'antd';

const { Option } = Select;
const Fragment = React.Fragment;

export default class NumberFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      operator: this.props.operator ? this.props.operator : 'EQUAL',
      field: props.field
    };
  }

  filterOperator = [
    { id: 'EQUAL', label: 'Equal' },
    { id: 'NOT_EQUAL', label: 'Not Equal' },
    { id: 'LESS_THAN', label: 'Less Than' },
    { id: 'GREATER_THAN', label: 'Greater Than' },
    { id: 'LESS_THAN_OR_EQUAL', label: 'Less/Equal to' },
    { id: 'GREATER_THAN_OR_EQUAL', label: 'Greater/Equal to' },
    { id: 'BETWEEN', label: 'Between' },
    { id: 'IS_EMPTY', label: 'Is Empty' },
    { id: 'NOT_EMPTY', label: 'Not Empty' }
  ];

  onElementChange = e => {
    e.preventDefault();
    if (this.state.operator === 'BETWEEN') {
      var start = this.props.form.getFieldsValue()[`${this.state.field}`];
      var end = this.props.form.getFieldsValue()[`${this.state.field}_end`];
      if (start && end) {
        this.setState({ values: [start, end] }, this.sentTOParent);
      } else {
        this.setState({ values: [] }, this.sentTOParent);
      }
    } else {
      this.setState({ value: this.props.form.getFieldsValue()[`${this.state.field}`] }, this.sentTOParent);
    }
  };

  onOperatorChange = e => {
    this.setState({ operator: e }, this.sentTOParent);
  };

  sentTOParent = () => {
    this.props.onFilter(this.state);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let inputs =
      this.state.operator === 'BETWEEN' ? (
        <Fragment>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator(`${this.state.field}`, {
                rules: []
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={this.props.field}
                  allowClear
                  onBlur={this.onElementChange}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator(`${this.state.field}_end`, {
                rules: []
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={this.props.field}
                  allowClear
                  onBlur={this.onElementChange}
                />
              )}
            </Form.Item>
          </Col>
        </Fragment>
      ) : (
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${this.state.field}`, {
              rules: []
            })(
              <InputNumber
                style={{ width: '100%' }}
                placeholder={this.props.field}
                allowClear
                onBlur={this.onElementChange}
              />
            )}
          </Form.Item>
        </Col>
      );

    return (
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator(`${this.state.field}_operator`, {
              initialValue: this.state.operator,
              rules: [{ required: true, message: 'Please select your country!' }]
            })(
              <Select onChange={this.onOperatorChange} style={{ width: '100%' }}>
                {this.filterOperator.map((item, key) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {inputs}
      </Row>
    );
  }
}
