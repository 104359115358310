import { MY_ORDERS_LOADED, VIEW_ORDER_LOADED } from 'redux/actions/order/action_types';

const initialStore = {
  page: 0,
  isLoading: true,
  pageSize: 15,
  filters: {},
  sorts: {},
  results: []
};

const load = (state, payload) => Object.assign({}, state, payload);

export default function order(state = initialStore, action) {
  switch (action.type) {
    case MY_ORDERS_LOADED:
      return load(state, action.payload);
    case VIEW_ORDER_LOADED:
      return load(state, action.payload);
    case 'MY_ERROR_ACTION':
      return state;
    default:
      return state;
  }
}
