import { PHONE_BRANDS_LOAD, PHONES_LOAD, PHONES_UNLOAD } from './action_types';

const loadPhoneBrands = () => ({
  type: PHONE_BRANDS_LOAD,
  payload: {}
});

const loadPhonesByBrandUrl = brandUrl => ({
  type: PHONES_LOAD,
  payload: { brandUrl: brandUrl }
});

const unloadPhones = () => ({
  type: PHONES_UNLOAD,
  payload: {}
});

export { loadPhoneBrands, loadPhonesByBrandUrl, unloadPhones };
