import {
  CHECKOUT_LOAD_PAYMENT_METHODS,
  CHECKOUT_LOADED_PAYMENT_METHODS,
  CHECKOUT_LOAD_SHIPPING_METHODS,
  CHECKOUT_LOADED_SHIPPING_METHODS,
  CHECKOUT_UPDATE_SHIPPING_METHOD,
  CHECKOUT_SHIPPING_METHOD_UPDATED,
  CHECKOUT_UPDATE_PAYMENT_METHOD,
  CHECKOUT_PAYMENT_METHOD_UPDATED,
  CHECKOUT_UNLOAD,
  CHECKOUT_CART,
  CHECKOUT_CART_SUCCESS
} from './action_types';

const loadPaymentMethods = () => ({
  type: CHECKOUT_LOAD_PAYMENT_METHODS,
  payload: {}
});

const loadedPaymentMethods = payload => ({
  type: CHECKOUT_LOADED_PAYMENT_METHODS,
  payload: payload
});

const loadShippingMethods = () => ({
  type: CHECKOUT_LOAD_SHIPPING_METHODS,
  payload: {}
});

const loadedShippingMethods = payload => ({
  type: CHECKOUT_LOADED_SHIPPING_METHODS,
  payload: payload
});

const unload = () => ({
  type: CHECKOUT_UNLOAD,
  payload: {}
});

const updateShippingMethod = payload => ({
  type: CHECKOUT_UPDATE_SHIPPING_METHOD,
  payload: payload
});

const shippingMethodUpdated = payload => ({
  type: CHECKOUT_SHIPPING_METHOD_UPDATED,
  payload: payload
});

const updatePaymentMethod = payload => ({
  type: CHECKOUT_UPDATE_PAYMENT_METHOD,
  payload: payload
});

const paymentMethodUpdated = payload => ({
  type: CHECKOUT_PAYMENT_METHOD_UPDATED,
  payload: payload
});

const checkoutCart = () => ({
  type: CHECKOUT_CART,
  payload: {}
});

const checkoutSuccess = () => ({
  type: CHECKOUT_CART_SUCCESS,
  payload: {}
});

export {
  loadPaymentMethods,
  loadedPaymentMethods,
  loadShippingMethods,
  loadedShippingMethods,
  unload,
  updateShippingMethod,
  shippingMethodUpdated,
  updatePaymentMethod,
  paymentMethodUpdated,
  checkoutCart,
  checkoutSuccess
};
