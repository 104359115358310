export const NO_IMG_URL = 'https://static.wplus.ca/assets/noimage.png';
export const NEW_IMG_URL = 'https://static.wplus.ca/assets/new.png';
export const SALE_IMG_URL = 'https://static.wplus.ca/assets/sale.png';
export const INTRO_IMG_URL = 'https://static.wplus.ca/assets/introductory-offer.png';
export const LIMITED_STOCK_IMG_URL = 'https://static.wplus.ca/assets/limited-stock.png';
export const CLEARANCE_IMG_URL = 'https://static.wplus.ca/assets/clearance.png';
export const OPTION1_IMG_URL = 'https://static.wplus.ca/assets/option1.png';
export const OPTION2_IMG_URL = 'https://static.wplus.ca/assets/option2.png';
export const OPTION3_IMG_URL = 'https://static.wplus.ca/assets/option3.png';

export const SPECIAL_PRICE_IMG_URLS = {
  SALE: SALE_IMG_URL,
  CLEARANCE: CLEARANCE_IMG_URL,
  INTRODUCTORY_OFFER: INTRO_IMG_URL,
  LIMITED_STOCK: LIMITED_STOCK_IMG_URL,
  OPTION1: OPTION1_IMG_URL,
  OPTION2: OPTION2_IMG_URL,
  OPTION3: OPTION3_IMG_URL
};

export const PRODUCT_TAG_URLS = {
  OPTION_1: 'https://static.wplus.ca/assets/product_tags/option1.png',
  OPTION_2: 'https://static.wplus.ca/assets/product_tags/option2.png',
  OPTION_3: 'https://static.wplus.ca/assets/product_tags/option3.png',
  OPTION_4: 'https://static.wplus.ca/assets/product_tags/option4.png',
  OPTION_5: 'https://static.wplus.ca/assets/product_tags/option5.png'
};
