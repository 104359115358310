import { fetchApi } from 'Utils/utils';

let loadMyAccountDetails = function() {
  return fetchApi.get('/api/v1/customer/my-account/');
};

let saveCustomer = function(payload) {
  return fetchApi.post('/api/v1/customer/register/', payload);
};

let resetPassword = function(payload) {
  return fetchApi.post('/api/v1/customer/resetPassword/', payload);
};

let forgetPassword = function(payload) {
  return fetchApi.post(`/api/v1/customer/password/forgot/${payload}/`, {});
};

let resetPasswordByCode = function(payload) {
  return fetchApi.post('/api/v1/customer/password/reset/', payload);
};

export { loadMyAccountDetails, saveCustomer, resetPassword, forgetPassword, resetPasswordByCode };
