import React from 'react';
import { connect } from 'react-redux';
import * as userSelectors from 'redux/selectors/user';
import * as productSelectors from 'redux/selectors/product';
import * as productActions from 'redux/actions/product';
import * as cartActions from 'redux/actions/cart';
import * as cartSelectors from 'redux/selectors/cart';
import { Spinner, ProductView } from 'components';
import styles from './viewproduct.module.less';

class ViewProduct extends React.Component {
  componentDidMount() {
    this.props.load(this.props.productId, this.props.variantId);
  }

  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    let content = (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
    if (this.props.loaded) {
      let p = this.props.singleProduct.product;
      let pv = this.props.singleProduct.variant;
      content = (
        <ProductView
          product={p}
          variant={pv}
          addToCart={this.props.addProductToCart}
          addToBag={this.props.addProductToBag}
          showPrices={this.props.showPrices}
          alreadyInCart={this.props.alreadyInCart}
        />
      );
    }
    return (
      <div className="cuiContentWrapper">
        <div className={styles.viewProductContainer}>{content}</div>
      </div>
    );
  }
}

function Wrapper(props) {
  return <ViewProduct key={props.variantId} {...props} />;
}

const mapDispatchToProps = {
  load: productActions.loadProduct,
  unload: productActions.unloadProducts,
  addProductToBag: cartActions.addToBag,
  addProductToCart: cartActions.addToCart
};

const mapStateToProps = (state, ownProps) => ({
  loaded: productSelectors.singleProductLoaded(state),
  singleProduct: productSelectors.getSingleProduct(state),
  productId: ownProps.match.params.productId,
  variantId: ownProps.match.params.variantId,
  showPrices: userSelectors.isUserLoggedIn(state),
  alreadyInCart: cartSelectors.getAlreadyInCart(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper);
