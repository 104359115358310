import React, { Component } from 'react';
import { Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './screenLoader.module.less';

class ScreenLoader extends Component {
  render() {
    return (
      <div className={styles.screenLoader}>
        <Spin tip="Processing..." indicator={<FontAwesomeIcon icon="spinner" className="fa-spin" />} />
      </div>
    );
  }
}

export default ScreenLoader;
