import { call, takeLatest, all, put } from 'redux-saga/effects';
import { MY_ORDERS_LOAD, VIEW_ORDER_LOAD, VIEW_ORDER_LOADED } from 'redux/actions/order/action_types';
import * as orderService from 'redux/services/order';
import * as listActions from 'redux/actions/list';
import * as apiErrorActions from 'redux/actions/apiError';

function* searchOrders() {
  yield takeLatest(MY_ORDERS_LOAD, function*(action) {
    try {
      let orderResponse = yield call(orderService.searchOrders, action.payload);
      orderResponse.listKey = action.payload.listKey;
      yield put(listActions.listLoaded(orderResponse));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* findOneOrder() {
  yield takeLatest(VIEW_ORDER_LOAD, function*(action) {
    let orderResponse = yield call(orderService.findOne, action.payload);
    yield put({
      type: VIEW_ORDER_LOADED,
      payload: orderResponse
    });
  });
}

export default function* orderSagas() {
  yield all([searchOrders(), findOneOrder()]);
}
