import React from 'react';
import { toPrice } from 'Utils/utils';
import { Form, Button, InputNumber } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import styles from './pricebox.module.less';

export default class PriceBox extends React.Component {
  constructor(props) {
    super(props);
    this.subscription = this.props.subject.subscribe(data => this.resetForm());
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  getTierPrices = () => {
    let tierPrices = Object.values(this.props.product.tierPrices)[0];
    if (tierPrices) {
      return tierPrices.slice(0);
    } else {
      return [];
    }
  };

  renderPriceType = () => {
    let tierPrices = this.getTierPrices();
    return this.props.product.specialPriceType != null
      ? this.renderSpecialPrice(tierPrices)
      : this.renderPrice(tierPrices);
  };

  renderSpecialPrice = tierPrices => {
    return (
      <div className={styles.specialPrice}>
        <span className={styles.oldPrice}>${toPrice(this.getRegularPrice(tierPrices))}</span> $
        {toPrice(this.props.product.specialPrice)}
      </div>
    );
  };

  getRegularPrice = tierPrices => {
    if (!tierPrices || tierPrices.length === 0) {
      return this.props.product.price;
    } else {
      let oneQty = tierPrices.find(e => e.qty === 1);
      if (oneQty) {
        return oneQty.price;
      } else {
        return this.props.product.price;
      }
    }
  };

  renderPrice = tierPrices => {
    return (
      <React.Fragment>
        <div className={styles.regularPrice}>${toPrice(this.getRegularPrice(tierPrices))}</div>
        {this.renderBulkPricing(tierPrices)}
      </React.Fragment>
    );
  };

  renderBulkPricing = tierPrices => {
    let filteredTierPrices = tierPrices.filter(p => p.qty > 1);
    if (filteredTierPrices.length === 0) {
      return <React.Fragment />;
    }
    filteredTierPrices.sort((a, b) => a.qty - b.qty);
    filteredTierPrices.unshift({ qty: 'Qty', price: '$' });
    let prices = filteredTierPrices.map((p, idx) => (
      <div key={idx} className={styles.bulkPricingUnit}>
        <div className={styles.qty}>{p.qty}</div>
        <div>{toPrice(p.price)}</div>
      </div>
    ));
    return <div className={styles.bulkPricing}>{prices}</div>;
  };

  addToBag = qty => this.props.addToBag(this.props.product.id, qty);

  resetForm = () => this.formRef.resetForm();

  render() {
    let priceBoxClass = styles.priceBox + ' price-box';
    return (
      <div className={styles.priceContainer}>
        <div className={priceBoxClass}>{this.renderPriceType()}</div>
        <div className={styles.addToCartBox}>
          <WrappedCartQtyForm
            wrappedComponentRef={form => (this.formRef = form)}
            addToBag={this.addToBag}
            addToCart={this.props.addToCart}
            skipBag={this.props.skipBag}
          />
          {this.props.alreadyInCart[this.props.product.id] && (
            <div className={styles.alreadyInCart}>{this.props.alreadyInCart[this.props.product.id]} in cart</div>
          )}
        </div>
      </div>
    );
  }
}

class CartQtyForm extends React.Component {
  constructor(props) {
    super(props);
    this.addToBag = _.debounce(props.addToBag, 300);
  }

  resetForm = () => this.props.form.resetFields();

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline" className={styles.qtyForm}>
        <Form.Item className={styles.itemRow}>
          {getFieldDecorator('qty', { initialValue: '' })(
            <InputNumber min={0} precision={0} className={styles.addToCartSpinner} onChange={this.addToBag} />
          )}
        </Form.Item>
        <Form.Item className={styles.itemRow}>
          {this.props.skipBag && (
            <Button className={styles.addToCartBtn} onClick={this.props.addToCart}>
              <FontAwesomeIcon icon="shopping-cart" />
              Add to Cart
            </Button>
          )}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedCartQtyForm = Form.create()(CartQtyForm);
