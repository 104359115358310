import React from 'react';
import { Row, Col } from 'antd';
import { ProductList } from 'components';
import styles from './widgets.module.less';

export default props => {
  let w = props.widget;
  return (
    <div className={styles.widgetContainer}>
      {w.displayTitle && (
        <Row gutter={24}>
          <Col span={24}>
            <div className={styles.title}>{w.title}</div>
          </Col>
        </Row>
      )}
      <ProductList
        products={w.products}
        alreadyInCart={props.alreadyInCart}
        maxPerRow={4}
        showPrices={props.showPrices}
        addProductToBag={props.addProductToBag}
        addProductToCart={props.addProductToCart}
        loadSingleProduct={props.loadSingleProduct}
        unloadSingleProduct={props.unloadSingleProduct}
        singleProduct={props.singleProduct}
        skipBag={props.skipBag || false}
      />
    </div>
  );
};
