import React, { Component } from 'react';
import Slider from 'react-slick';
import styles from './phoneslider.module.less';
import './slick.less';

class PhoneSlider extends Component {
  componentWillMount() {}

  componentWillUnmount() {}

  render() {
    let items;
    let settings = {
      dots: true,
      infinite: true,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 10,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1367,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 601,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    if (this.props.slides) {
      items = this.props.slides.map((item, key) => (
        <div key={key}>
          <a
            className={styles.phone}
            href={`/phone/${item.url}`}
            title={item.title}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={item.largeImage ? item.largeImage.path : item.asset.path} alt={item.name} />
          </a>
        </div>
      ));
    }

    return (
      <div className={styles.latestPhones}>
        <h1 className={styles.title}>Latest Devices</h1>
        <Slider {...settings}>{items}</Slider>
      </div>
    );
  }
}

export default PhoneSlider;
