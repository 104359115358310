export const CHECKOUT_LOAD_SHIPPING_METHODS = 'CHECKOUT_LOAD_SHIPPING_METHODS';
export const CHECKOUT_LOADED_SHIPPING_METHODS = 'CHECKOUT_LOADED_SHIPPING_METHODS';
export const CHECKOUT_UNLOAD = 'CHECKOUT_UNLOAD';
export const CHECKOUT_LOAD_PAYMENT_METHODS = 'CHECKOUT_LOAD_PAYMENT_METHODS';
export const CHECKOUT_LOADED_PAYMENT_METHODS = 'CHECKOUT_LOADED_PAYMENT_METHODS';
export const CHECKOUT_UPDATE_SHIPPING_METHOD = 'CHECKOUT_UPDATE_SHIPPING_METHOD';
export const CHECKOUT_SHIPPING_METHOD_UPDATED = 'CHECKOUT_SHIPPING_METHOD_UPDATED';
export const CHECKOUT_UPDATE_PAYMENT_METHOD = 'CHECKOUT_UPDATE_PAYMENT_METHOD';
export const CHECKOUT_PAYMENT_METHOD_UPDATED = 'CHECKOUT_PAYMENT_METHOD_UPDATED';
export const CHECKOUT_CART = 'CHECKOUT_CART';
export const CHECKOUT_CART_SUCCESS = 'CHECKOUT_CART_SUCCESS';
