import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import {
  PRODUCTS_LOAD,
  PRODUCTS_FILTERS_LOAD,
  PRODUCTS_LOAD_PAGE_COUNT,
  PRODUCTS_SINGLE_LOAD,
  PHONE_LATEST_LOAD
} from '../actions/product/action_types';
import * as productActions from 'redux/actions/product';
import * as productService from 'redux/services/product';
import * as productSelectors from 'redux/selectors/product';

function* fetchProducts() {
  yield takeLatest(PRODUCTS_LOAD, function*(action) {
    let activeFilters = yield select(productSelectors.getActiveFilters);
    let encodedFilterString = encodeURIComponent(JSON.stringify(activeFilters));
    let url = new URL(window.location.href);
    url.searchParams.set('activeFilters', encodedFilterString);
    window.history.pushState({}, '', url);
    let products = yield call(productService.fetchProducts, activeFilters);
    yield put(productActions.productsLoaded(products));
  });
}

function* fetchProductsPageCount() {
  yield takeLatest(PRODUCTS_LOAD_PAGE_COUNT, function*(action) {
    let activeFilters = yield select(productSelectors.getActiveFilters);
    let products = yield call(productService.fetchProductsPageCount, activeFilters);
    yield put(productActions.productsPageCountLoaded(products));
  });
}

function* fetchProductFilters() {
  yield takeLatest(PRODUCTS_FILTERS_LOAD, function*(action) {
    let filters = yield call(productService.fetchProductFilters, action.payload);
    yield put(productActions.productFiltersLoaded(filters));
    let urlSearchParams = new URL(window.location.href).searchParams;
    let activeFiltersQueryString = urlSearchParams.get('activeFilters');
    let activeFilters = {};
    if (activeFiltersQueryString) {
      activeFilters = JSON.parse(decodeURIComponent(activeFiltersQueryString));
    } else {
      activeFilters = {
        listBy: filters.listBy,
        value: filters.value,
        baseCategory: filters.baseCategory,
        categories: filters.selCategories,
        attributes: filters.selAttrs,
        phoneBrands: filters.selPhoneBrands,
        searchTerms: filters.searchTerms
      };
    }
    yield put(productActions.updateFiltersAndLoadProducts(activeFilters));
  });
}

function* fetchProduct() {
  yield takeLatest(PRODUCTS_SINGLE_LOAD, function*(action) {
    let product = yield productService.fetchProduct(action.payload.productId, action.payload.variantId);
    yield put(productActions.productLoaded(product));
  });
}

function* fetchLatestPhones() {
  yield takeLatest(PHONE_LATEST_LOAD, function*(action) {
    let product = yield productService.fetchLatestPhones(action.payload);
    yield put(productActions.latestPhoneLoaded(product));
  });
}

export default function* productSagas() {
  yield all([fetchProducts(), fetchProductFilters(), fetchProductsPageCount(), fetchProduct(), fetchLatestPhones()]);
}
