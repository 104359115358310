// @flow
import React, { Component } from 'react';
import { Header, Footer } from './components';
import Auth from './components/Auth/requireAuth';
import { Spinner, ScreenLoader } from 'components';
import style from './app.module.less';
import './components/font-awesome-lib';
import { BackTop } from 'antd';
import { Router, Route, Switch, withRouter } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import * as pages from '../pages';
import { connect } from 'react-redux';
import * as configActions from 'redux/actions/config';
import * as configSelectors from 'redux/selectors/config';
import * as userActions from 'redux/actions/user';
import * as userSelectors from 'redux/selectors/user';
import * as cartSelectors from 'redux/selectors/cart';
import * as homepageActions from 'redux/actions/homepage';
import { ERROR_401, ERROR_403, ERROR_404, ERROR_500 } from 'redux/actions/apiError/action_types';
import { ScreenLoaderContext, withEventBus } from 'context';
import ReactGA from 'react-ga';

//ReactGA.initialize('UA-153193410-1');

const history = createHistory();
/*
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
*/

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false
    };
  }

  componentWillMount() {
    this.props.loadConfig();
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  showLoader = () => this.setState({ showLoader: true });
  hideLoader = () => this.setState({ showLoader: false });

  render() {
    return (
      <div>
        {!this.props.isConfigLoaded && <Spinner />}
        {this.props.isConfigLoaded && (
          <Router history={history}>
            <ScreenLoaderContext.Provider value={{ showLoader: this.showLoader, hideLoader: this.hideLoader }}>
              <ScrollToTop
                setMessages={this.props.setMessages}
                hideLoader={this.hideLoader}
                resetSession={this.props.resetSession}
              >
                <div className={style.App}>
                  <Header
                    logo={this.props.logo}
                    menu={this.props.menu}
                    isUserLoggedIn={this.props.isUserLoggedIn}
                    userName={this.props.userName}
                    loginBtnClickHandler={this.props.loginBtnClickHandler}
                    userLoginErrMsg={this.props.userLoginErrMsg}
                    logoutBtnClickHandler={this.props.logoutBtnClickHandler}
                    showCart={this.props.isUserLoggedIn}
                    cartTotal={this.props.cartTotal}
                    cartItemCount={this.props.cartItemCount}
                  />
                  <Switch>
                    <Route path="/" exact component={pages.Homepage} />
                    <Route path="/phoneBrand/" exact component={pages.Devices} />
                    <Route path="/phonebrand/:brandUrl/" component={pages.PhoneBrand} />
                    <Route path="/category/" component={pages.Categories} />
                    <Route path="/products/category/:categoryUrl/" component={pages.ProductsByCategory} />
                    <Route path="/products/search/" exact component={pages.ProductsBySearch} />
                    <Route path="/phone/:phoneUrl/" component={pages.ProductsByPhone} />
                    <Route path="/viewCart/" component={pages.ViewCart} />
                    <Route path="/checkout/shipping/" component={pages.CheckoutShippingStep} />
                    <Route path="/checkout/payment/" component={pages.CheckoutPaymentStep} />
                    <Route path="/checkout/confirm/" component={pages.CheckoutConfirmStep} />
                    <Route path="/checkout/success/" component={pages.CheckoutSuccessStep} />
                    <Route path="/product/:productId/variant/:variantId" component={pages.ViewProduct} />
                    <Route path="/customer/my-account/" exact component={Auth(pages.AccountHome)} />
                    <Route path="/customer/my-account/order/:orderId/" component={Auth(pages.OrderView)} />
                    <Route path="/customer/my-account/invoice/:id/" component={Auth(pages.InvoiceView)} />
                    <Route path="/customer/my-account/register/" exact component={pages.AccountCreate} />
                    <Route path="/customer/forget-password/" exact component={pages.ForgetPassword} />
                    <Route path="/customer/reset-password-by-code/:code/" exact component={pages.ResetPasswordByCode} />
                    <Route path="/customer/login/" exact component={pages.LoginPage} />
                    <Route path="/page/:url/" exact component={pages.PageView} />
                    <Route path="/not-authorized/" exact component={pages.ErrorPages.NotAuthorized} />
                    <Route path="/server-error/" exact component={pages.ErrorPages.ServerError} />
                    <Route component={pages.ErrorPages.PageNotFound} />
                  </Switch>
                  <Footer isUserLoggedIn={this.props.isUserLoggedIn} />
                </div>
              </ScrollToTop>
            </ScreenLoaderContext.Provider>
          </Router>
        )}
        {this.state.showLoader && <ScreenLoader />}
        <BackTop className={style.backTopBtn} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadConfig: configActions.loadConfig,
  loginBtnClickHandler: userActions.userLogin,
  logoutBtnClickHandler: userActions.userLogout,
  resetSession: userActions.resetSession,
  setMessages: homepageActions.setMessages
};

const mapStateToProps = (state, ownProps) => ({
  menu: configSelectors.getMenu(state),
  isConfigLoaded: configSelectors.isConfigLoaded(state),
  logo: configSelectors.getLogo(state),
  userName: userSelectors.getName(state),
  isUserLoggedIn: userSelectors.isUserLoggedIn(state),
  userLoginErrMsg: userSelectors.getErrMsg(state),
  cartTotal: cartSelectors.getTotal(state),
  cartItemCount: cartSelectors.getItemCount(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

const ScrollToTop = withEventBus(
  withRouter(
    class extends Component {
      constructor(props) {
        super(props);
        this.subscriptions = [];
      }
      componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
          window.scrollTo(0, 0);
        }
      }

      componentDidMount() {
        this.subscriptions.push(
          this.props.eventBusSubscribe(ERROR_401, action => {
            this.props.resetSession();
            this.props.hideLoader();
            this.props.setMessages({
              errorMsg:
                'Please login to gain access to the requested page.' +
                ' You might have got logged out because you signed in different place.'
            });
            this.props.history.push('/customer/login/');
          })
        );
        this.subscriptions.push(
          this.props.eventBusSubscribe(ERROR_403, action => {
            this.props.hideLoader();
            this.props.history.push('/not-authorized/');
          })
        );
        this.subscriptions.push(
          this.props.eventBusSubscribe(ERROR_404, action => {
            this.props.hideLoader();
            this.props.history.push('/page-not-found/');
          })
        );
        this.subscriptions.push(
          this.props.eventBusSubscribe(ERROR_500, action => {
            this.props.hideLoader();
            this.props.history.push('/server-error/');
          })
        );
      }

      componentWillUnmount() {
        this.subscriptions.forEach(s => s.unsubscribe());
      }
      render() {
        return this.props.children;
      }
    }
  )
);
