// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as invoiceAction from 'redux/actions/invoice';
import * as listActions from 'redux/actions/list';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { toPrice, toDateString } from 'Utils/utils';

const Fragment = React.Fragment;
const columns = [
  {
    title: 'Invoice Id',
    dataIndex: 'code',
    defaultSortOrder: 'DESC',
    sorter: true
  },
  {
    title: 'Invoice Date',
    dataIndex: 'invoiceDate',
    sorter: true,
    render: d => toDateString(d)
  },
  {
    title: 'Name',
    dataIndex: 'customer',
    render: (customer, obj) => {
      if (customer) {
        var firstName = '',
          lastName = '';
        if (customer.firstName) firstName = customer.firstName;
        if (customer.lastName) lastName = customer.lastName;
        return `${firstName} ${lastName}`;
      } else {
        return '';
      }
    },
    sorter: true
  },
  {
    title: 'Shipping Amt',
    dataIndex: 'shippingAmt',
    sorter: true
  },
  {
    title: 'Taxes',
    dataIndex: 'taxes',
    sorter: true
  },
  {
    title: 'Total',
    dataIndex: 'total',
    render: amt => {
      return toPrice(amt);
    },
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status.name',
    sorter: true
  },
  {
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    render: id => (
      <Fragment>
        <Link to={`/customer/my-account/invoice/${id}/`}>view</Link>
      </Fragment>
    )
  }
];

class InvoiceList extends Component {
  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    const defaultFilters = this.props.showArchive
      ? {
          archive: {
            _field: 'archive',
            operator: 'IS',
            value: 'archived'
          }
        }
      : {};

    return (
      <div>
        <Row>
          <Col span={24}>
            <WrapperTable
              title={this.props.showArchive ? 'Archived Invoice List' : 'Invoice List'}
              key={this.props.showArchive}
              filters={this.props.showFilters ? filters : []}
              defaultFilters={defaultFilters}
              columns={columns}
              data={this.props.listData}
              listKey={this.props.listKey}
              api={this.props.searchInvoice}
            />
          </Col>
        </Row>
        {!this.props.showArchive && (
          <Row>
            <Col span={24}>
              <span style={{ fontWeight: 'bold', marginTop: '64px' }}>
                If you want to view invoices older than 6 months, please{' '}
                <Button type="primary" onClick={() => this.props.onClickShowArchive()}>
                  click here
                </Button>
              </span>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const filters = [
  {
    field: 'code',
    type: 'TEXT',
    operator: 'EQUAL'
  },
  {
    field: 'subTotal',
    type: 'NUMBER'
  },
  {
    field: 'taxes',
    type: 'NUMBER'
  },
  {
    field: 'total',
    type: 'NUMBER'
  },
  {
    field: 'status',
    type: 'OPTION',
    options: [{ id: 'UNPAID', name: 'Unpaid' }, { id: 'PAID', name: 'Paid' }, { id: 'CANCELLED', name: 'Cancelled' }]
  }
];

const mapDispatchToProps = {
  searchInvoice: invoiceAction.searchInvoice,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceList);
