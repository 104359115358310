import React, { Component } from 'react';
import { Form, Row, Col, Select, DatePicker } from 'antd';

const { Option } = Select;

export default class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      operator: this.props.operator ? this.props.operator : 'EQUAL',
      field: props.field
    };
  }

  onElementChange = (date, dateString) => {
    var val = date === null ? '' : date.startOf('day').format('YYYY-MM-DD');
    this.setState(
      {
        value: val
      },
      this.sentTOParent
    );
  };

  onOperatorChange = e => {
    this.setState({ operator: e }, this.sentTOParent);
  };

  sentTOParent = () => {
    this.props.onFilter(this.state);
  };

  filterOperator = [
    { id: 'EQUAL', label: 'Equal' },
    { id: 'NOT_EQUAL', label: 'Not Equal' },
    { id: 'LESS_THAN', label: 'Less Than' },
    { id: 'GREATER_THAN', label: 'Greater Than' },
    { id: 'LESS_THAN_OR_EQUAL', label: 'Less/Equal to' },
    { id: 'GREATER_THAN_OR_EQUAL', label: 'Greater/Equal to' },
    { id: 'BETWEEN', label: 'Between' },
    { id: 'IS_EMPTY', label: 'Is Empty' },
    { id: 'NOT_EMPTY', label: 'Not Empty' },
    { id: 'IS', label: 'Is' }
  ];

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator(`${this.state.field}_operator`, {
              initialValue: this.state.operator,
              rules: [{ required: true, message: 'Please select your country!' }]
            })(
              <Select onChange={this.onOperatorChange} style={{ width: '100%' }}>
                {this.filterOperator.map((item, key) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${this.state.field}`, {
              rules: []
            })(<DatePicker onChange={this.onElementChange} />)}
          </Form.Item>
        </Col>
      </Row>
    );
  }
}
