import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './success.module.less';

class Success extends Component {
  render() {
    return (
      <div className="cuiContentWrapper">
        <div className={styles.success}>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 12, offset: 6 }}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon icon="check-square" />
              </div>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12, offset: 6 }}>
              <div className={styles.msgContainer}>
                Thank you for placing your order. We will soon send you a confirmation email with complete details of
                the order.
              </div>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12, offset: 6 }}>
              <div className={styles.btnContainer}>
                <Button size="large" type="primary" onClick={() => this.props.history.push('/')}>
                  Go to Home
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Success));
