import { call, takeLatest, all, put } from 'redux-saga/effects';
import {
  CUSTOMER_LOAD,
  CUSTOMER_LOADED,
  CUSTOMER_REGISTER,
  CUSTOMER_SUMBIT_OK,
  CUSTOMER_FORGET_PASSWORD,
  CUSTOMER_PASSWORD_RESET_BY_CODE,
  CUSTOMER_PASSWORD_RESET
} from 'redux/actions/customer/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as customerService from 'redux/services/customer';

function* loadMyAccountDetails() {
  yield takeLatest(CUSTOMER_LOAD, function*(action) {
    try {
      let myAccountDetail = yield call(customerService.loadMyAccountDetails);
      yield put({
        type: CUSTOMER_LOADED,
        payload: myAccountDetail
      });
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* saveCustomer() {
  yield takeLatest(CUSTOMER_REGISTER, function*(action) {
    try {
      let customer = yield call(customerService.saveCustomer, action.payload);
      yield put({
        type: CUSTOMER_SUMBIT_OK,
        payload: customer
      });
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* resetPassword() {
  yield takeLatest(CUSTOMER_PASSWORD_RESET, function*(action) {
    try {
      let customer = yield call(customerService.resetPassword, action.payload);
      yield put({
        type: CUSTOMER_SUMBIT_OK,
        payload: customer
      });
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* forgetPassword() {
  yield takeLatest(CUSTOMER_FORGET_PASSWORD, function*(action) {
    try {
      let customer = yield call(customerService.forgetPassword, action.payload);
      yield put({
        type: CUSTOMER_SUMBIT_OK,
        payload: customer
      });
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* resetPasswordCode() {
  yield takeLatest(CUSTOMER_PASSWORD_RESET_BY_CODE, function*(action) {
    try {
      let customer = yield call(customerService.resetPasswordByCode, action.payload);
      yield put({
        type: CUSTOMER_SUMBIT_OK,
        payload: customer
      });
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* accountSagas() {
  yield all([loadMyAccountDetails(), saveCustomer(), resetPassword(), forgetPassword(), resetPasswordCode()]);
}
