import React, { Component } from 'react';
import { Row, Col, notification } from 'antd';
import { ProductList } from 'components';
import { Filters } from './components';
import { withEventBus, withScreenLoader } from 'context';
import { CART_ADD_TO_CART_SUCCESS, CART_ADD_TO_CART_IGNORED } from 'redux/actions/cart/action_types';
import styles from './productbrowser.module.less';

class ProductBrowser extends Component {
  constructor(props) {
    super(props);
    this.productListContainerRef = React.createRef();
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.loadProductFilters(this.props.baseFilters);
    window.addEventListener('scroll', this.onScroll, false);
    this.subscriptions.push(
      this.props.eventBusSubscribe(CART_ADD_TO_CART_SUCCESS, action => {
        this.props.hideScreenLoader();
        notification['success']({
          message: 'Added to cart',
          description: 'All products are added to cart successfully.',
          duration: 2.5
        });
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(CART_ADD_TO_CART_IGNORED, action => {
        this.props.hideScreenLoader();
      })
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unloadProducts();
  }

  onScroll = () => {
    if (
      this.props.morePagesAvailable &&
      !this.props.areProductsLoading &&
      this.productListContainerRef.current != null &&
      this.productListContainerRef.current.containerRef.current != null &&
      this.productListContainerRef.current.containerRef.current.scrollHeight - window.scrollY < window.innerHeight + 500
    ) {
      this.props.loadNextPage();
    }
  };

  addProductToCart = () => {
    this.props.showScreenLoader();
    this.props.addProductToCart();
  };

  render() {
    return (
      <Row gutter={24}>
        <Col span={5} className={styles.FilterColumn}>
          <Filters
            filters={this.props.filters}
            activeFilters={this.props.activeFilters}
            toggleFilter={this.props.toggleFilter}
            refineByFilters={this.props.refineByFilters}
          />
        </Col>
        <Col span={19}>
          <ProductList
            products={this.props.productsList}
            alreadyInCart={this.props.alreadyInCart}
            maxPerRow={4}
            title={this.props.title}
            showPrices={this.props.showPrices}
            productsLoading={this.props.areProductsLoading}
            productsLoaded={this.props.areProductsLoaded}
            ref={this.productListContainerRef}
            addProductToBag={this.props.addProductToBag}
            addProductToCart={this.addProductToCart}
            singleProduct={this.props.singleProduct}
            loadSingleProduct={this.props.loadSingleProduct}
            unloadSingleProduct={this.props.unloadSingleProduct}
          />
        </Col>
      </Row>
    );
  }
}

export default withScreenLoader(withEventBus(ProductBrowser));
