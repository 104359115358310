import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Button, Layout } from 'antd';
import { DisplayField, AddressView, Spinner } from 'components';
import * as orderActions from 'redux/actions/order';
import * as orderSelectors from 'redux/selectors/order';
import { toPrice, toDateString, wrapUrlWithAuthToken } from 'Utils/utils';
import style from './orderview.module.less';

const { Header, Content } = Layout;

class Orderview extends Component {
  componentDidMount() {
    this.props.findOrderByUid(this.props.match.params.orderId);
  }

  loadProducts = lines => {
    if (lines)
      return lines.map((item, index) => (
        <tr key={item.id} className={style.row}>
          <td className={style.product}> {index + 1}</td>
          <td className={style.product}> {item.name}</td>
          <td className={style.cell}> {item.sku}</td>
          <td className={style.total}> {item.price}</td>
          <td className={style.number}> {item.qtyOrdered}</td>
          <td className={style.total}> {toPrice(item.subTotal)}</td>
        </tr>
      ));
  };

  generateOrderView = (myDetails, lines) => {
    return (
      <Layout>
        <Header className={style.header}>
          <Row gutter={24}>
            <Col span={16}>
              <h1>View Order # {myDetails.code}</h1>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Button
                href={wrapUrlWithAuthToken(`/api/v1/order/print/?id=${myDetails.id}`)}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex="-1"
              >
                Print Order
              </Button>
            </Col>
          </Row>
        </Header>
        <Content>
          <div style={{ padding: '20px' }}>
            <Row gutter={24}>
              <Col span={8}>
                <DisplayField label="Customer Id" value={myDetails.code} />
              </Col>
              <Col span={8}>
                <DisplayField label="Status" value={myDetails.status.name} />
              </Col>
              <Col span={8}>
                <DisplayField label="Order Date Time" value={toDateString(myDetails.orderDateTime)} />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <DisplayField
                  label="Customer Name"
                  value={`${myDetails.customerFirstName} ${myDetails.customerLastName}`}
                />
              </Col>
              <Col span={8}>
                <DisplayField label="Payment Method" value={myDetails.paymentMethod.name} />
              </Col>
              <Col span={8}>
                <DisplayField label="Shipping Method" value={myDetails.shippingMethod.name} />
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col span={24}>
                <h2>ORDER INFORMATION</h2>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={10}>
                <AddressView address={myDetails.billingAddress} title="Billing Address" />
              </Col>
              <Col span={12}>
                <AddressView address={myDetails.shippingAddress} title="Shipping Address" />
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col span={24}>
                <table className={style.table}>
                  <thead>
                    <tr className={style.row}>
                      <th>SR#</th>
                      <th>PRODUCT NAME</th>
                      <th>SKU</th>
                      <th className={style.header_number}>PRICE</th>
                      <th className={style.header_number}>QTY</th>
                      <th className={style.header_number}>SUBTOTAL</th>
                    </tr>
                  </thead>
                  <tbody>{lines}</tbody>
                </table>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={20} />
              <Col span={4}>
                <table className={style.table}>
                  <tbody>
                    <tr className={style.row}>
                      <td>Sub Total</td>
                      <td className={style.total}>{toPrice(myDetails.subTotal)}</td>
                    </tr>
                    <tr className={style.row}>
                      <td>Disc. {myDetails.couponCode}</td>
                      <td className={style.total}>{toPrice(myDetails.discountAmt)}</td>
                    </tr>
                    <tr className={style.row}>
                      <td>Shipping Amt</td>
                      <td className={style.total}>{toPrice(myDetails.shippingAmt)}</td>
                    </tr>
                    <tr className={style.row}>
                      <td>Tax</td>
                      <td className={style.total}>{toPrice(myDetails.taxes)}</td>
                    </tr>
                    <tr className={style.row}>
                      <td>Total</td>
                      <td className={style.total}>{toPrice(myDetails.total)}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    );
  };

  render() {
    let myDetails = this.props.order.search;
    let lines = this.loadProducts(myDetails.items);
    let content;
    if (myDetails.code) {
      content = this.generateOrderView(myDetails, lines);
    } else {
      content = <Spinner />;
    }
    return <div className="cuiContentWrapper">{content}</div>;
  }
}

const mapDispatchToProps = {
  findOrderByUid: orderActions.watchfindOne
};

const mapStateToProps = (state, ownProps) => ({
  order: orderSelectors.viewOrder(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orderview);
