import React from 'react';
import { Steps } from 'antd';
import styles from './steps.module.less';

const Step = Steps.Step;

export default class CheckoutSteps extends React.Component {
  getSteps = () => ['Shipping', 'Billing', 'Confirmation'];

  render() {
    return (
      <div className="cuiContentWrapper">
        <div className={styles.checkoutSteps}>
          <Steps current={this.props.current}>
            {this.getSteps().map((step, idx) => (
              <Step key={idx} title={step} />
            ))}
          </Steps>
        </div>
      </div>
    );
  }
}
