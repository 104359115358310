import {
  CART_MINICART_LOADED,
  CART_ADD_TO_BAG,
  CART_ADD_TO_CART,
  CART_ADD_TO_CART_SUCCESS,
  CART_ADD_TO_CART_IGNORED,
  CART_CLEAR_BAG,
  CART_LOAD,
  CART_LOADED,
  CART_UNLOAD,
  CART_UPDATE_QTY,
  CART_REMOVE_ITEMS,
  CART_CLEAR,
  CART_IMAGE_LOAD,
  CART_IMAGE_LOADED,
  CART_APPLY_COUPON,
  CART_APPLY_COUPON_SUCCESS,
  CART_APPLY_COUPON_ERROR,
  CART_REORDER
} from './action_types';

const minicartLoaded = payload => ({
  type: CART_MINICART_LOADED,
  payload: payload
});

const addToBag = (id, qty, doAddToCart = false) => {
  let actions = [];
  actions.push({
    type: CART_ADD_TO_BAG,
    payload: { productVariantId: id, qty: qty }
  });
  if (doAddToCart) {
    actions.push(addToCart());
  }
  return actions;
};

const addToCart = () => ({
  type: CART_ADD_TO_CART,
  payload: {}
});

const addToCartSuccess = () => ({
  type: CART_ADD_TO_CART_SUCCESS,
  payload: {}
});

const addToCartIgnored = () => ({
  type: CART_ADD_TO_CART_IGNORED,
  payload: {}
});

const clearBag = () => ({
  type: CART_CLEAR_BAG,
  payload: {}
});

const load = cartId => ({
  type: CART_LOAD,
  payload: { cartId: cartId }
});

const loaded = cart => ({
  type: CART_LOADED,
  payload: cart
});

const unload = () => ({
  type: CART_UNLOAD,
  payload: {}
});

const updateQty = items => ({
  type: CART_UPDATE_QTY,
  payload: items
});

const clear = () => ({
  type: CART_CLEAR,
  payload: {}
});

const removeItems = productVariantIds => {
  let items = {};
  productVariantIds.forEach(id => (items[id] = 0));
  return {
    type: CART_REMOVE_ITEMS,
    payload: items
  };
};

const loadImage = (productId, variantId) => ({
  type: CART_IMAGE_LOAD,
  payload: { variantId: variantId, productId: productId }
});

const imageLoaded = payload => ({
  type: CART_IMAGE_LOADED,
  payload: payload
});

const applyCoupon = payload => ({
  type: CART_APPLY_COUPON,
  payload: payload
});
const applyCouponSuccess = () => ({
  type: CART_APPLY_COUPON_SUCCESS,
  payload: {}
});
const applyCouponError = payload => ({
  type: CART_APPLY_COUPON_ERROR,
  payload: payload
});

const reorder = orderId => ({
  type: CART_REORDER,
  payload: { orderId: orderId }
});

export {
  minicartLoaded,
  addToBag,
  addToCart,
  clearBag,
  loaded,
  load,
  unload,
  updateQty,
  removeItems,
  clear,
  loadImage,
  imageLoaded,
  applyCoupon,
  addToCartSuccess,
  addToCartIgnored,
  applyCouponSuccess,
  applyCouponError,
  reorder
};
