import React from 'react';
import { ImageGallery, PriceBox } from 'components';
import { Subject } from 'rxjs';
import { Row, Col } from 'antd';
import { productStatusToStr } from 'Utils/utils';
import styles from './productview.module.less';

export default class ProductView extends React.Component {
  constructor(props) {
    super(props);
    this.subject = new Subject();
  }

  componentWillUnmount() {
    this.subject.complete();
  }

  addToCart = () => {
    this.props.addToCart();
    this.subject.next('resetForm');
  };

  render() {
    let pv = this.props.variant;
    let imgs = pv.assets.map(a => ({
      id: a.id,
      path: a.path,
      type: a.type,
      alt: pv.name
    }));
    let stockClass = styles.stockStatus + ' ' + styles[pv.stockStatus];
    return (
      <div className={styles.viewProduct}>
        <Row gutter={24}>
          <Col span={10}>
            <div className={styles.imgGallery}>
              <ImageGallery images={imgs} />
            </div>
          </Col>
          <Col span={14}>
            <h2 className="page-title">{pv.name}</h2>
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: pv.description }} />
            <p className={stockClass}>{productStatusToStr(pv.stockStatus)}</p>
            <div className={styles.addInfo}>
              <div className={styles.infoContainer}>
                <div>SKU:</div>
                <div>{pv.sku}</div>
              </div>
            </div>
            {this.props.showPrices && pv.stockStatus === 'IN_STOCK' && (
              <div className={styles.priceBox}>
                <PriceBox
                  product={pv}
                  alreadyInCart={this.props.alreadyInCart}
                  addToBag={this.props.addToBag}
                  addToCart={this.addToCart}
                  skipBag={true}
                  subject={this.subject}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
