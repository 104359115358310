import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner, CartSummary } from 'components';
import { InputNumber, Table, Button, Form, Row, Col, Alert, Modal, Popover, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import * as cartActions from 'redux/actions/cart';
import * as cartSelectors from 'redux/selectors/cart';
import * as utils from 'Utils/utils';
import { NO_IMG_URL } from 'Utils/constants';
import { withEventBus } from 'context/eventbus';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import styles from './viewcart.module.less';

const { confirm } = Modal;

class ViewCart extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      globalErrors: []
    };
  }

  componentDidMount() {
    this.props.cartLoad();
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = action.payload.globalErrors;
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.cartUnload();
  }

  imageExists = variantId => Object.keys(this.props.images).indexOf(variantId) !== -1;

  loadImage = (productId, variantId) => {
    if (!this.imageExists(variantId)) {
      this.props.loadImage(productId, variantId);
    }
  };

  applyCoupon = payload => {
    this.setState({
      globalErrors: []
    });
    this.props.applyCoupon(payload);
  };

  renderProductImage = (variantId, productVariantName) => {
    if (this.imageExists(variantId)) {
      let img = this.props.images[variantId] ? this.props.images[variantId] : NO_IMG_URL;
      return <img src={img} alt={productVariantName} className={styles.tooltipImage} />;
    } else {
      return <Spin size="large" />;
    }
  };

  getColumns = getFieldDecorator => [
    {
      title: '#',
      dataIndex: 'idx',
      key: 'idx',
      className: (() => styles.centeredColName)()
    },
    {
      title: 'Name',
      dataIndex: 'productVariantName',
      key: 'name',
      render: (text, record) => (
        <Popover
          content={this.renderProductImage(record.productVariantId, record.productVariantName)}
          trigger="hover"
          placement="right"
          onVisibleChange={() => this.loadImage(record.productId, record.productVariantId)}
        >
          {text}
        </Popover>
      )
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      className: (() => styles.centeredColName)(),
      render: text => <div>${utils.toPrice(text)}</div>
    },
    {
      title: 'Qty',
      key: 'qty',
      className: (() => styles.centeredColName)(),
      width: 175,
      render: (text, record) => (
        <div>
          {getFieldDecorator(record.productVariantId, {
            initialValue: record.qty
          })(<InputNumber min={0} max={1000} precision={0} />)}
          <Button htmlType="submit" className={styles.updateQtyBtn}>
            <FontAwesomeIcon icon="sync-alt" />
          </Button>
        </div>
      )
    },
    {
      title: 'Subtotal',
      dataIndex: 'subTotal',
      key: 'subTotal',
      className: (() => styles.centeredColName)(),
      render: text => <div>${utils.toPrice(text)}</div>
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => this.removeCartItem(record)}>
            Remove
          </Button>
        </div>
      ),
      className: (() => styles.centeredColName)()
    }
  ];

  getItems = () => {
    let items = this.props.cart.items.sort((i1, i2) => {
      if (i1.productId !== i2.productId) {
        return i1.productId < i2.productId ? -1 : 1;
      }
      return i1.productVariantId < i2.productVariantId ? -1 : 1;
    });
    return items.map((item, index) => Object.assign({}, item, { idx: index + 1 }));
  };

  paginationConf = () => false;
  // ({
  //   defaultPageSize: 25,
  //   position: 'both'
  // });

  updateItemsQty = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let items = {};
        Object.keys(values).forEach(k => (items[k] = values[k]));
        this.props.cartUpdateItemsQty(items);
      }
    });
  };

  removeCartItem = product => {
    confirm({
      title: 'Remove product from cart',
      content: `Are you sure you want to remove "${product.productVariantName}" from cart?`,
      onOk: () => {
        this.props.cartRemoteItems([product.productVariantId]);
      },
      onCancel() {}
    });
  };

  clearCart = () => {
    confirm({
      title: 'Clear cart',
      content: 'Are you sure you want to remove all products from cart?',
      onOk: () => {
        this.props.clearCart();
      },
      onCancel() {}
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="cuiContentWrapper">
        <div className={styles.viewCart}>
          <h2 className="page-title">Shopping Cart</h2>
          {!this.props.cart.isLoaded && <Spinner />}

          {this.props.cart.isLoaded && this.props.cart.items.length === 0 && (
            <div className={styles.emptyCart}>
              You have no items in your cart. <Link to="/">Click here</Link> to contine shopping.
            </div>
          )}

          {this.props.cart.isLoaded && this.props.cart.items.length > 0 && (
            <Row gutter={24}>
              <Col span={18}>
                {this.props.cart.removedItems.length > 0 && (
                  <Alert
                    className={styles.removedItemsAlert}
                    type="warning"
                    showIcon
                    message="Following products have been removed from your cart as they are currently not available:"
                    description={
                      <ul>
                        {this.props.cart.removedItems.map((i, idx) => (
                          <li key={idx}>{i.productVariantName}</li>
                        ))}
                      </ul>
                    }
                  />
                )}
                {this.state.globalErrors.length > 0 && (
                  <Alert
                    className={styles.globalErrors}
                    type="error"
                    showIcon
                    closable="true"
                    description={
                      <ul className={styles.globalErrorsList}>
                        {this.state.globalErrors.map((err, idx) => (
                          <li key={idx}>{err}</li>
                        ))}
                      </ul>
                    }
                  />
                )}
                <Button type="primary" className={styles.clearCartBtn} size="large" onClick={this.clearCart}>
                  Clear Cart
                </Button>
                <Form onSubmit={this.updateItemsQty}>
                  <Table
                    className={styles.itemsTable}
                    rowKey={r => r.id}
                    columns={this.getColumns(getFieldDecorator)}
                    dataSource={this.getItems()}
                    pagination={this.paginationConf()}
                    bordered
                  />
                </Form>
              </Col>
              <Col span={6}>
                <CartSummary cart={this.props.cart} showCheckoutBtn={true} applyCoupon={this.applyCoupon} />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  cartLoad: cartActions.load,
  cartUnload: cartActions.unload,
  cartUpdateItemsQty: cartActions.updateQty,
  cartRemoteItems: cartActions.removeItems,
  clearCart: cartActions.clear,
  loadImage: cartActions.loadImage,
  applyCoupon: cartActions.applyCoupon
};

const mapStateToProps = (state, ownProps) => ({
  cartId: cartSelectors.getCartId(state),
  cart: cartSelectors.getFullCart(state),
  images: cartSelectors.getImages(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(Form.create()(ViewCart)));
