// @flow
import React, { Component } from 'react';
import style from './search.module.less';
import { Input } from 'antd';
import { withRouter } from 'react-router-dom';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ''
    };
  }

  onSearch = value => {
    let cleanValue = value.trim();
    if (cleanValue.length === 0) {
      return;
    }
    this.setState({ searchText: '' });
    this.props.history.push(`/products/search/?s=${value.trim()}`);
  };

  render() {
    return (
      <div className={style.globalSearchWrapper}>
        <Input.Search
          className={style.globalSearch}
          size="large"
          style={{ width: '100%' }}
          placeholder="Search Products by name, SKU or UPC"
          enterButton
          value={this.state.searchText}
          onChange={event => this.setState({ searchText: event.target.value })}
          onSearch={this.onSearch}
        />
      </div>
    );
  }
}

export default withRouter(Search);
