import {
  HOMEPAGE_CONTENT_LOADED,
  HOMEPAGE_SET_MESSAGES,
  HOMEPAGE_UNSET_MESSAGES
} from '../actions/homepage/action_types';

const initialStore = {
  loaded: false,
  widgets: [],
  imageSlider: [],
  errorMsg: '',
  infoMsg: '',
  successMsg: '',
  warnMsg: ''
};

const homepageContentLoaded = (state, payload) =>
  Object.assign({}, state, {
    loaded: true,
    ...payload
  });

const setMessages = (state, payload) => Object.assign({}, state, payload);

const unsetMessages = (state, payload) =>
  Object.assign({}, state, {
    errorMsg: '',
    infoMsg: '',
    successMsg: '',
    warnMsg: ''
  });

export default function homepageReducer(state = initialStore, action) {
  switch (action.type) {
    case HOMEPAGE_CONTENT_LOADED:
      return homepageContentLoaded(state, action.payload);
    case HOMEPAGE_SET_MESSAGES:
      return setMessages(state, action.payload);
    case HOMEPAGE_UNSET_MESSAGES:
      return unsetMessages(state, action.payload);
    default:
      return state;
  }
}
