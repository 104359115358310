import { call, put, takeLatest, all } from 'redux-saga/effects';
import { PHONE_BRANDS_LOAD, PHONE_BRANDS_LOADED, PHONES_LOAD, PHONES_LOADED } from '../actions/attributes/action_types';
import * as attributesService from '../services/attributes';

function* fetchPhoneBrands() {
  yield takeLatest(PHONE_BRANDS_LOAD, function*(action) {
    let phoneBrands = yield call(attributesService.fetchPhoneBrands);
    yield put({
      type: PHONE_BRANDS_LOADED,
      payload: phoneBrands
    });
  });
}

function* fetchPhones() {
  yield takeLatest(PHONES_LOAD, function*(action) {
    let phones = yield call(attributesService.fetchPhonesByBrandUrl, action.payload.brandUrl);
    yield put({
      type: PHONES_LOADED,
      payload: phones
    });
  });
}

export default function* attributesSagas() {
  yield all([fetchPhoneBrands(), fetchPhones()]);
}
