import React, { useEffect, useState } from 'react';
import { Device } from './components';
import _ from 'lodash';
import { Row, Col, Tag } from 'antd';
import { createChunks } from 'Utils/utils';
import styles from './devicebrowser.module.less';

const MAX_DEVICES_PER_ROW = 6;
const { CheckableTag } = Tag;

export default ({ devices }) => {
  const [selectedYears, setSelectedYears] = useState([]);
  const [allYears, setAllYears] = useState([]);
  const [sortedDevices, setSortedDevices] = useState([]);

  useEffect(() => {
    const devicesSorted = devices.sort((a, b) => {
      let yearSort = b.yearOfLaunch - a.yearOfLaunch;
      return yearSort !== 0 ? yearSort : b.sortOrder - a.sortOrder;
    });
    setSortedDevices(devicesSorted);
    const years = _.uniq(devices.map(d => d.yearOfLaunch).sort((a, b) => b - a));
    setAllYears(years);
    setSelectedYears(years);
  }, [devices]);

  const toggleState = (c, year) => {
    if (c) {
      setSelectedYears(selectedYears.concat(year));
    } else {
      if (selectedYears.length === allYears.length) {
        setSelectedYears([year]);
      } else if (selectedYears.length === 1) {
        setSelectedYears(allYears);
      } else {
        setSelectedYears(selectedYears.filter(y => y !== year));
      }
    }
  };

  let filteredDevices = sortedDevices.filter(d => selectedYears.includes(d.yearOfLaunch));
  return (
    <div className={styles.deviceBrowserBox}>
      <div className={styles.yearsBox}>
        <Row>
          <Col span={18} offset={6}>
            {allYears.map(y => (
              <CheckableTag key={y} checked={selectedYears.includes(y)} onChange={c => toggleState(c, y)}>
                {y}
              </CheckableTag>
            ))}
          </Col>
        </Row>
      </div>
      <DeviceGroup devices={filteredDevices} />
    </div>
  );
};

const DeviceGroup = ({ devices }) => {
  let rowGutter = { xs: 8, sm: 16, md: 24 };
  const deviceGroups = Object.values(_.uniqBy(devices.map(d => d.group), g => g.id)).sort(
    (a, b) => a.sortOrder - b.sortOrder
  );
  const deviceChunks = devicesToDisplay => createChunks(devicesToDisplay, MAX_DEVICES_PER_ROW);
  const devicesByGroupId = _.groupBy(devices, d => d.group.id);
  return (
    <>
      {deviceGroups.map(grp => (
        <Row className={styles.deviceGroupBox} key={grp.id}>
          <Col className={styles.deviceGroupImg} span={6}>
            <img alt="deviceGroup" src={grp.image.path} />
          </Col>
          <Col className={styles.deviceGroupDevices} span={18}>
            {deviceChunks(devicesByGroupId[grp.id]).map((c, idx) => (
              <Row key={idx} gutter={rowGutter} type="flex" justify="start" align="middle">
                {c.map((a, aIdx) => (
                  <Col key={aIdx} span={24 / MAX_DEVICES_PER_ROW}>
                    <Device device={a} />
                  </Col>
                ))}
              </Row>
            ))}
          </Col>
        </Row>
      ))}
    </>
  );
};
