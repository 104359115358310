import React, { Component } from 'react';
import { NO_IMG_URL } from 'Utils/constants';
import { Link } from 'react-router-dom';
import styles from './attribute.module.less';

export default class Attribute extends Component {
  render() {
    let a = this.props.attribute;
    let imgSrc = a.asset == null ? NO_IMG_URL : a.asset.path;
    return (
      <div className={styles.attributeBox}>
        <Link to={a.url} className={styles.attrLink}>
          <div className={styles.content}>
            <img src={imgSrc} alt={a.title} className={styles.attrImg} />
            <p className={styles.title}>{a.title}</p>
          </div>
        </Link>
      </div>
    );
  }
}
