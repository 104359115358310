import { toPrice } from 'Utils/utils';

export const getCart = store => (store.cart ? store.cart : {});
export const getMiniCart = store => getCart(store).miniCart;
export const getAlreadyInCart = store => getMiniCart(store).items;
export const getTotal = store => toPrice(getMiniCart(store).total);
export const getCartId = store => getMiniCart(store).cartId;
export const getItemCount = store => getMiniCart(store).itemCount;
export const getBag = store => getCart(store).bag;
export const getFullCart = store => getCart(store).cart;
export const getImages = store => getCart(store).images;
