import React, { Component } from 'react';
import { Table, Button, Tooltip } from 'antd';
import { isObjectEmpty } from 'Utils/utils';
import { Navbar } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterForm from './Filter/filterForm';
import { withEventBus } from 'context/eventbus';
import { LIST_LOADED, LIST_RELOAD } from 'redux/actions/list/action_types';
import style from './table.module.less';

class WrapperTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      filters: this.props.defaultFilters || {},
      defaultFilters: this.props.defaultFilters || {},
      loaded: false
    };
    this.subscriptions = [];
  }

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(LIST_LOADED, action => {
        this.setState({
          loaded: true
        });
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(LIST_RELOAD, action => {
        this.stateUpdatedOnFilter();
      })
    );

    var sortOrder = {};
    this.props.columns.forEach(function(i) {
      if (i.hasOwnProperty('defaultSortOrder')) {
        sortOrder.field = i.dataIndex;
        sortOrder.order = i.defaultSortOrder;
      }
    });

    this.props.api(this.getParams(this.props.data, this.state.filters, sortOrder));
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reload = () => {
    this.stateUpdatedOnFilter();
  };

  getParams = (props, filter, sorter) => {
    var sortParam = this.getSortParams(sorter);
    return {
      listKey: this.props.listKey,
      page: props.page - 1,
      pageSize: props.pageSize,
      sorts: sortParam,
      filters: filter,
      pagination: {
        total: props.total,
        current: props.page - 1,
        pageSize: props.pageSize
      }
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.props.data.isLoading = true;
    this.props.data.page = pagination.current;
    this.props.data.pagination.current = pagination.current;
    this.props.api(this.getParams(this.props.data, this.state.filters, sorter));
  };

  getSortParams = sorter => {
    if (!isObjectEmpty(sorter)) return {};
    return {
      [sorter.field]: sorter.order === 'ascend' ? 'ASC' : 'DESC'
    };
  };

  stateUpdatedOnFilter = () => {
    this.handleTableChange(this.props.data.pagination, this.state.filters, this.props.data.sorts);
  };

  getFilter = f => {
    this.setState(
      {
        filters: Object.assign({}, f, this.state.defaultFilters)
      },
      this.stateUpdatedOnFilter
    );
  };

  onClear = () => {
    this.setState(
      {
        filters: this.state.defaultFilters
      },
      this.stateUpdatedOnFilter
    );
  };

  showFilter = () => {
    this.setState({ showForm: this.state.showForm ? false : true });
  };

  render() {
    var showFilterButton =
      this.props.filters && this.props.filters.length > 0 ? (
        <Button shape="circle" onClick={this.showFilter}>
          <Tooltip title="Search">
            <FontAwesomeIcon icon="filter" />
          </Tooltip>
        </Button>
      ) : (
        <span />
      );

    let filterForm =
      this.state.showForm && this.props.filters && this.props.filters.length > 0 ? (
        <FilterForm filters={this.props.filters} onClear={this.onClear} onSearch={this.getFilter} />
      ) : (
        <div />
      );

    return (
      <div className={style.container}>
        <Navbar title={this.props.title}>
          {this.props.tableAction}
          <div className={style.filterButton}>{showFilterButton}</div>
        </Navbar>
        {filterForm}
        <Table
          columns={this.props.columns}
          rowKey={record => record.id}
          dataSource={this.state.loaded ? this.props.data.data : []}
          pagination={this.props.data.pagination}
          loading={!this.state.loaded || this.props.data.isLoading}
          bordered
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default withEventBus(WrapperTable);
