import React, { Component } from 'react';
import { Form, Row, Col, Select } from 'antd';

const { Option } = Select;

export default class OptionFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      options: this.props.options,
      operator: this.props.operator ? this.props.operator : 'IN',
      field: props.field
    };
  }

  filterOperator = [{ id: 'IN', label: 'In' }, { id: 'NOT_IN', label: 'Not In' }];

  onElementChange = e => {
    this.setState({ values: e }, this.sentTOParent);
  };

  onOperatorChange = e => {
    this.setState({ operator: e }, this.sentTOParent);
  };

  sentTOParent = () => {
    this.props.onFilter(this.state);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator(`${this.state.field}_operator`, {
              initialValue: this.state.operator,
              rules: [{ required: true, message: 'Please select your country!' }]
            })(
              <Select onChange={this.onOperatorChange} style={{ width: '100%' }}>
                {this.filterOperator.map((item, key) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${this.state.field}`, {
              rules: []
            })(
              <Select
                mode="tags"
                placeholder={this.props.field}
                onChange={this.onElementChange}
                style={{ width: '100%' }}
              >
                {this.props.options.map((item, key) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  }
}
