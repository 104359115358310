import React, { Component } from 'react';
import { withScreenLoader } from 'context';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Icon } from 'antd';
import style from './megamenu.module.less';

const toUrl = item => {
  switch (item.itemType) {
    case 'CATEGORY':
      let value = '?' + item.values.map(v => `id=${encodeURIComponent(v)}`).join('&');
      return '/category/' + value;
    case 'PRODUCT_LIST':
      return '/products/category/' + deriveValue(item.values);
    case 'PHONE_BRAND':
      return '/phoneBrand/' + deriveValue(item.values);
    case 'PHONE':
      return '/phone/' + deriveValue(item.values);
    default:
      return deriveValue(item.values);
  }
};

const deriveValue = values => (values.length === 0 || values[0] === 'all' ? '' : values[0]);

class MegaMenu extends Component {
  hideDropDownMenu = () => {
    this.props.showScreenLoader();
    setTimeout(() => {
      this.props.hideScreenLoader();
    }, 1200);
  };
  navigateToUrl = url => {
    return event => {
      event.preventDefault();
      this.hideDropDownMenu();
      this.props.history.push(url);
    };
  };
  buildSubMenu = children => {
    var rows = [];
    var subMenuArray = [];
    for (var i = 0; i < children.length; i++) {
      if (i % 4 === 0 && i !== 0) {
        rows.push(
          <Row key={i} gutter={24}>
            {subMenuArray}
          </Row>
        );
        subMenuArray = [];
      }
      subMenuArray.push(<MegaMenuSubList submenu={children[i]} key={children[i].item.id} />);
    }
    if (subMenuArray.length !== 0) {
      rows.push(
        <Row key={i} gutter={24}>
          {subMenuArray}
        </Row>
      );
    }
    return <div className={style.menuSub}>{rows}</div>;
  };

  render() {
    let items = this.props.items.map(i => {
      return (
        <li key={i.item.id}>
          <button onClick={this.navigateToUrl(toUrl(i.item))}>
            <span>
              {i.item.name}
              {i.children.length !== 0 && <Icon type="caret-down" className={style.expandIcon} />}
            </span>
          </button>
          {i.children.length !== 0 && this.buildSubMenu(i.children)}
        </li>
      );
    });
    return (
      <nav className={style.megamenu}>
        <div className={style.container}>
          <ul className={style.menuMain}>{items}</ul>
        </div>
      </nav>
    );
  }
}

const MegaMenuSubList = withRouter(
  withScreenLoader(
    class extends Component {
      hideDropDownMenu = () => {
        this.props.showScreenLoader();
        setTimeout(() => {
          this.props.hideScreenLoader();
        }, 1200);
      };
      navigateToUrl = url => {
        return event => {
          event.preventDefault();
          this.hideDropDownMenu();
          this.props.history.push(url);
        };
      };
      render() {
        let i = this.props.submenu;
        return (
          <Col key={i.item.id} span={6}>
            <button className={style.menuCategory} onClick={this.navigateToUrl(toUrl(i.item))}>
              <span>{i.item.name}</span>
            </button>
            {i.children.length !== 0 && (
              <Row className={style.listMenus}>
                {i.children.map(j => (
                  <Col key={j.item.id} className={style.listMenusItems}>
                    <button onClick={this.navigateToUrl(toUrl(j.item))}>
                      <span>
                        &gt; {j.item.name} {j.item.newMarker && <span className={style.newMarker}>New</span>}
                      </span>
                    </button>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        );
      }
    }
  )
);

export default withRouter(withScreenLoader(MegaMenu));
