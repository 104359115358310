import React, { Component } from 'react';
import { AttributeBrowser, Spinner } from 'components';
import * as queryString from 'query-string';
import { connect } from 'react-redux';
import * as categorySelectors from 'redux/selectors/category';
import * as categoryActions from 'redux/actions/category';
import styles from './categories.module.less';

class Categories extends Component {
  componentDidMount() {
    this.props.loadSubCategories(this.props.categories);
  }

  componentWillUnmount() {
    this.props.unloadSubCategories();
  }

  render() {
    let content;
    if (this.props.areSubCategoriesLoaded) {
      content = <AttributeBrowser attributes={this.props.subCategoriesList} />;
    } else {
      content = <Spinner />;
    }
    return (
      <div className="cuiContentWrapper">
        <div className={styles.categoriesBox}>
          <h2 className="page-title">Categories</h2>
          {content}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadSubCategories: categoryActions.loadSubCategories,
  unloadSubCategories: categoryActions.unloadSubCategories
};

class CategoriesWrapper extends React.Component {
  render() {
    let key = this.props.categories
      ? Array.isArray(this.props.categories)
        ? this.props.categories.join('|')
        : this.props.categories
      : 'FakeKey';
    return <Categories key={key} {...this.props} />;
  }
}

const convertToAttributes = function(list) {
  return list.map(l =>
    Object.assign({
      asset: l.asset,
      title: l.name,
      url: l.anchor ? `/category/${l.url}/` : `/products/category/${l.url}/`,
      count: l.count
    })
  );
};

const mapStateToProps = (state, ownProps) => ({
  subCategoriesList: convertToAttributes(categorySelectors.getSubCategoriesList(state)),
  areSubCategoriesLoaded: categorySelectors.areSubCategoriesLoaded(state),
  categories: queryString.parse(ownProps.location.search).id
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesWrapper);
