import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styles from './displayField.module.less';

class DisplayField extends Component {
  componentWillMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <Row className={styles.displayField}>
        <Col className={styles.label}>{this.props.label}</Col>
        <Col className={styles.value}>{this.props.value}</Col>
      </Row>
    );
  }
}

export default DisplayField;
