import {
  CHECKOUT_LOADED_PAYMENT_METHODS,
  CHECKOUT_LOADED_SHIPPING_METHODS,
  CHECKOUT_UNLOAD
} from 'redux/actions/checkout/action_types';

const initialStore = {
  paymentMethods: [],
  shippingMethods: []
};

const loadedPaymentMethods = (state, payload) =>
  Object.assign({}, state, { paymentMethods: payload.sort((a, b) => a.sortOrder - b.sortOrder) });

const loadedShippingMethods = (state, payload) =>
  Object.assign({}, state, { shippingMethods: payload.sort((a, b) => a.sortOrder - b.sortOrder) });

export default function(state = initialStore, action) {
  switch (action.type) {
    case CHECKOUT_LOADED_PAYMENT_METHODS:
      return loadedPaymentMethods(state, action.payload);
    case CHECKOUT_LOADED_SHIPPING_METHODS:
      return loadedShippingMethods(state, action.payload);
    case CHECKOUT_UNLOAD:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
