import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'components';
import * as pageActions from 'redux/actions/page';
import * as pageSelectors from 'redux/selectors/page';
import style from './pageview.module.less';

class StaticPageView extends Component {
  componentDidMount() {
    this.props.findByUrl(this.props.match.params.url);
  }

  render() {
    let content;
    if (this.props.isLoaded) {
      var body = this.props.page.body;
      content = <div dangerouslySetInnerHTML={{ __html: body }}></div>;
    } else {
      content = <Spinner />;
    }
    return (
      <div className={style.container}>
        <h1 className={style.title}>{this.props.page.title}</h1>
        <div className={style.content}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  findByUrl: pageActions.pageLoad
};

const mapStateToProps = (state, ownProps) => ({
  page: pageSelectors.get(state),
  isLoaded: pageSelectors.isLoaded(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaticPageView);
