import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import {
  CART_ADD_TO_CART,
  CART_LOAD,
  CART_UPDATE_QTY,
  CART_REMOVE_ITEMS,
  CART_CLEAR,
  CART_IMAGE_LOAD,
  CART_APPLY_COUPON,
  CART_REORDER
} from 'redux/actions/cart/action_types';
import * as cartService from 'redux/services/cart';
import * as cartActions from 'redux/actions/cart';
import * as cartSelectors from 'redux/selectors/cart';
import * as productService from 'redux/services/product';
import * as apiErrorActions from 'redux/actions/apiError';

function* addToCart() {
  yield takeLatest(CART_ADD_TO_CART, function*(action) {
    let cartId = yield select(cartSelectors.getCartId);
    let bag = yield select(cartSelectors.getBag);
    if (Object.keys(bag).length === 0) {
      yield put(cartActions.addToCartIgnored());
      return;
    }
    let miniCart = yield call(cartService.addToCart, { cartId: cartId, items: bag });
    yield put(cartActions.clearBag());
    yield put(cartActions.minicartLoaded(miniCart));
    yield put(cartActions.addToCartSuccess());
  });
}

function* loadCart() {
  yield takeLatest(CART_LOAD, function*(action) {
    let cartId = yield select(cartSelectors.getCartId);
    let cart = yield call(cartService.loadCart, cartId);
    yield put(cartActions.loaded(cart));
    yield put(cartActions.minicartLoaded(cartToMiniCart(cart)));
  });
}

function* updateItemsQty() {
  yield takeLatest(CART_UPDATE_QTY, function*(action) {
    let cartId = yield select(cartSelectors.getCartId);
    let cart = yield call(cartService.updateItemsQty, { cartId: cartId, items: action.payload });
    yield put(cartActions.loaded(cart));
    yield put(cartActions.minicartLoaded(cartToMiniCart(cart)));
  });
}

function* removeItems() {
  yield takeLatest(CART_REMOVE_ITEMS, function*(action) {
    let cartId = yield select(cartSelectors.getCartId);
    let cart = yield call(cartService.removeItems, { cartId: cartId, items: action.payload });
    yield put(cartActions.loaded(cart));
    yield put(cartActions.minicartLoaded(cartToMiniCart(cart)));
  });
}

function* clear() {
  yield takeLatest(CART_CLEAR, function*(action) {
    let cartId = yield select(cartSelectors.getCartId);
    let miniCart = yield call(cartService.clear, cartId);
    yield put([cartActions.minicartLoaded(miniCart), cartActions.load(miniCart.cartId)]);
  });
}

function* loadImage() {
  yield takeLatest(CART_IMAGE_LOAD, function*(action) {
    let image = yield call(productService.fetchVariantImage, action.payload);
    yield put(cartActions.imageLoaded(image));
  });
}

function* applyCoupon() {
  yield takeLatest(CART_APPLY_COUPON, function*(action) {
    try {
      let cart = yield call(cartService.applyCoupon, action.payload);
      yield put(cartActions.loaded(cart));
      yield put(cartActions.minicartLoaded(cartToMiniCart(cart)));
      yield put(cartActions.applyCouponSuccess());
    } catch (err) {
      if (err.status === 422) {
        yield put(cartActions.applyCouponError(err.response));
      } else {
        yield put(apiErrorActions.handle(err));
      }
    }
  });
}

function* reorder() {
  yield takeLatest(CART_REORDER, function*(action) {
    try {
      let cartId = yield select(cartSelectors.getCartId);
      let miniCart = yield call(cartService.reorder, Object.assign({}, action.payload, { cartId: cartId }));
      yield put(cartActions.minicartLoaded(miniCart));
      yield put(cartActions.addToCartSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

const cartToMiniCart = cart => {
  let items = {};
  cart.items.forEach(item => (items[item.id] = item.qty));
  let minicart = {};
  minicart.cartId = cart.id;
  minicart.itemCount = Object.keys(items).length;
  minicart.items = items;
  minicart.total = cart.total;
  return minicart;
};

export default function* cartSagas() {
  yield all([addToCart(), loadCart(), updateItemsQty(), removeItems(), clear(), loadImage(), applyCoupon(), reorder()]);
}
