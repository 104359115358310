export const PRODUCTS_LOAD = 'PRODUCTS_LOAD';
export const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
export const PRODUCTS_UNLOAD = 'PRODUCTS_UNLOAD';
export const PRODUCTS_UPDATE_FILTERS = 'PRODUCTS_UPDATE_FILTERS';
export const PRODUCTS_FILTERS_LOAD = 'PRODUCTS_FILTERS_LOAD';
export const PRODUCTS_FILTERS_LOADED = 'PRODUCTS_FILTERS_LOADED';
export const PRODUCTS_LOAD_NEXT_PAGE = 'PRODUCTS_LOAD_NEXT_PAGE';
export const PRODUCTS_LOAD_PAGE_COUNT = 'PRODUCTS_LOAD_PAGE_COUNT';
export const PRODUCTS_PAGE_COUNT_LOADED = 'PRODUCTS_PAGE_COUNT_LOADED';
export const PRODUCTS_TOGGLE_FILTER = 'PRODUCTS_TOGGLE_FILTER';
export const PRODUCTS_REFINE_BY_FILTERS = 'PRODUCTS_REFINE_BY_FILTERS';
export const PRODUCTS_SINGLE_LOAD = 'PRODUCTS_SINGLE_LOAD';
export const PRODUCTS_SINGLE_LOADED = 'PRODUCTS_SINGLE_LOADED';
export const PRODUCTS_UNLOAD_SINGLE_PRODUCT = 'PRODUCTS_UNLOAD_SINGLE_PRODUCT';
export const PHONE_LATEST_LOAD = 'PHONE_LATEST_LOAD';
export const PHONE_LATEST_LOADED = 'PHONE_LATEST_LOADED';
