import React from 'react';
import { CartSummary } from 'components';
import CheckoutSteps from 'pages/Checkout/steps';
import { Row, Col, Button } from 'antd';
import * as cartActions from 'redux/actions/cart';
import * as cartSelectors from 'redux/selectors/cart';
import * as checkoutActions from 'redux/actions/checkout';
import * as checkoutSelectors from 'redux/selectors/checkout';
import * as homepageActions from 'redux/actions/homepage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withEventBus, withScreenLoader } from 'context';
import { CHECKOUT_CART_SUCCESS } from 'redux/actions/checkout/action_types';
import { CART_LOADED } from 'redux/actions/cart/action_types';
import cstyles from '../checkout.module.less';
import styles from './confirm.module.less';

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentWillMount() {
    this.props.loadCart();
    this.props.loadPaymentMethods();
    this.props.loadShippingMethods();
    this.props.showScreenLoader();
    this.subscriptions.push(
      this.props.eventBusSubscribe(CHECKOUT_CART_SUCCESS, () => {
        this.props.hideScreenLoader();
        this.props.history.push('/checkout/success/');
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(CART_LOADED, () => {
        this.props.hideScreenLoader();
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unloadCart();
    this.props.unloadCheckout();
  }

  getMethod = (method, methods = []) => {
    let m = methods.find(m => m.id === method);
    return m ? m : '';
  };

  placeOrder = () => {
    this.props.showScreenLoader();
    this.props.checkoutCart();
  };

  render() {
    let shippingMethod = this.getMethod(this.props.cart.shippingMethod, this.props.shippingMethods);
    let paymentMethod = this.getMethod(this.props.cart.paymentMethod, this.props.paymentMethods);
    return (
      <div className="cuiContentWrapper">
        <div className={styles.paymentCheckout}>
          <Row gutter={24}>
            <Col span={18}>
              <CheckoutSteps current={3} />
              <h3 className={cstyles.sectionHeading}>Shipping Address</h3>
              <AddressRender address={this.props.cart.shippingAddress} />
              <h3 className={cstyles.sectionHeading}>Shipping Method</h3>
              {shippingMethod && (
                <div className={styles.method}>
                  <span>{shippingMethod.name}</span> - {shippingMethod.description}
                </div>
              )}
              <h3 className={cstyles.sectionHeading}>Billing Address</h3>
              <AddressRender address={this.props.cart.billingAddress} />
              <h3 className={cstyles.sectionHeading}>Payment Method</h3>
              {paymentMethod && (
                <div className={styles.method}>
                  <span>{paymentMethod.name}</span> - {paymentMethod.description}
                </div>
              )}
              {this.props.cart.comments && (
                <div>
                  <h3 className={cstyles.sectionHeading}>Comments</h3>
                  <div className={styles.comments}>{this.props.cart.comments}</div>
                </div>
              )}
              <div className={cstyles.actionBtnContainer}>
                <Button
                  className={styles.viewCart}
                  size="large"
                  onClick={() => {
                    this.props.history.push('/viewCart');
                  }}
                >
                  View Cart
                </Button>
                <Button className={cstyles.nextBtn} type="primary" size="large" onClick={this.placeOrder}>
                  Place Order
                </Button>
              </div>
            </Col>
            <Col span={6}>
              <CartSummary cart={this.props.cart} showCheckoutBtn={false} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const AddressRender = function(props) {
  let a = props.address;
  let render = a ? (
    <div className={styles.addressContainer}>
      {a.name && <div className={styles.addName}>{a.name}</div>}
      {a.company && <div className={styles.addCompany}>{a.company}</div>}
      <div className={styles.addLine}>{a.street1}</div>
      {a.street2 && <div className={styles.addLine}>{a.street2}</div>}
      <div className={styles.addLine}>
        {a.city}, {a.province} {a.postalCode}.
      </div>
      {a.phoneNo && <div className={styles.addLine}>Phone: {a.phoneNo}</div>}
    </div>
  ) : (
    <div />
  );
  return render;
};

const mapDispatchToProps = {
  loadCart: cartActions.load,
  unloadCart: cartActions.unload,
  loadPaymentMethods: checkoutActions.loadPaymentMethods,
  loadShippingMethods: checkoutActions.loadShippingMethods,
  unloadCheckout: checkoutActions.unload,
  setMessages: homepageActions.setMessages,
  checkoutCart: checkoutActions.checkoutCart
};

const mapStateToProps = (state, ownProps) => ({
  cart: cartSelectors.getFullCart(state),
  paymentMethods: checkoutSelectors.getPaymentMethods(state),
  shippingMethods: checkoutSelectors.getShippingMethods(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(withRouter(Confirm))));
