import React from 'react';
import { Input, Form, Button, Select, Row, Col } from 'antd';
import styles from './addressform.modules.less';

class AddressForm extends React.Component {
  componentDidMount() {
    this.props.loadProvinces();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.saveAddress(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {getFieldDecorator('id', {
          initialValue: this.props.data.id
        })(<Input type="hidden" />)}
        {getFieldDecorator('version', {
          initialValue: this.props.data.version
        })(<Input type="hidden" />)}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item className={styles.row}>
              {getFieldDecorator('name', {
                initialValue: this.props.data.name,
                rules: []
              })(<Input placeholder="Name" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.row}>
              {getFieldDecorator('company', {
                initialValue: this.props.data.company,
                rules: []
              })(<Input placeholder="Company Name" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.row}>
              {getFieldDecorator('street1', {
                initialValue: this.props.data.street1,
                rules: [{ required: true, message: 'Street 1 is required' }]
              })(<Input placeholder="Street 1" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.row}>
              {getFieldDecorator('street2', {
                initialValue: this.props.data.street2,
                rules: []
              })(<Input placeholder="Street 2" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.row}>
              {getFieldDecorator('city', {
                initialValue: this.props.data.city,
                rules: [{ required: true, message: 'City is required' }]
              })(<Input placeholder="City" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.row}>
              {getFieldDecorator('postalCode', {
                initialValue: this.props.data.postalCode,
                rules: [{ required: true, message: 'Postal Code is required' }]
              })(<Input placeholder="Postal Code" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.row}>
              {getFieldDecorator('provinceId', {
                initialValue: this.props.data.provinceId,
                rules: [{ required: true, message: 'Select a province' }]
              })(
                <Select placeholder="Province">
                  {this.props.provinces.map(p => (
                    <Select.Option key={p.id} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.row}>
              {getFieldDecorator('phoneNo', {
                initialValue: this.props.data.phoneNo,
                rules: [{ required: false }]
              })(<Input placeholder="Phone No" />)}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit">
            {this.props.data.id !== 0 ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(AddressForm);
