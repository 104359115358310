import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Descriptions, Button } from 'antd';
import { AddressView, Spinner, Navbar } from 'components';
import * as invoiceSelectors from 'redux/selectors/invoice';
import * as invoiceActions from 'redux/actions/invoice';
import { toPrice, toDateString, wrapUrlWithAuthToken } from 'Utils/utils';
import style from './invoiceview.module.less';

class InvoiceView extends Component {
  componentDidMount() {
    this.props.findByUid(this.props.match.params.id);
  }

  componentWillUnmount() {}

  loadProducts = lines => {
    if (lines)
      return lines.map((item, key) => (
        <tr key={key} className={style.row}>
          <td className={style.product}> {key + 1}</td>
          <td className={style.product}> {item.name}</td>
          <td className={style.cell}> {item.sku}</td>
          <td className={style.number}> {item.qtyOrdered}</td>
          <td className={style.total}> {item.price}</td>
          <td className={style.total}> {toPrice(item.subTotal)}</td>
        </tr>
      ));
  };

  generateInvoiceView = (o, lines) => {
    return (
      <div>
        <Descriptions bordered column={2} size="small">
          <Descriptions.Item label="Customer Name">
            {o.customer.firstName} {o.customer.lastName}
          </Descriptions.Item>
          <Descriptions.Item label="Invoice Date">{toDateString(o.invoiceDate)}</Descriptions.Item>
          <Descriptions.Item label="Status">{o.status.name}</Descriptions.Item>
          <Descriptions.Item label="Order">{o.orderCode}</Descriptions.Item>
          <Descriptions.Item label="Payment Method">
            {o.paymentMethod == null ? '' : o.paymentMethod.name}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Date">{toDateString(o.paymentDate)}</Descriptions.Item>
          <Descriptions.Item label="Billing Address">
            <AddressView address={o.billingAddress} />
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Address">
            <AddressView address={o.shippingAddress} />
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Method">{o.orderShippingMethod.name}</Descriptions.Item>
          <Descriptions.Item />
          <Descriptions.Item span={2} label="Notes">
            {o.notes}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <h2>Shipment Details</h2>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <table className={style.table}>
              <thead>
                <tr className={style.row}>
                  <th className={style.header}>Name</th>
                  <th>Tracking #</th>
                  <th>Method</th>
                </tr>
              </thead>
              <tbody>
                {o.shipments.map((item, key) => (
                  <tr key={key} className={style.row}>
                    <td className={style.product}> {item.name}</td>
                    <td className={style.cell}> {item.tracking}</td>
                    <td className={style.cell}> {item.method !== null ? item.method.name : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <h2>Shipped Items</h2>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <table className={style.table}>
              <thead>
                <tr className={style.row}>
                  <th className={style.header}>SR #</th>
                  <th className={style.header}>PRODUCT NAME</th>
                  <th>SKU</th>
                  <th className={style.header_number}>QTY</th>
                  <th className={style.header_number}>PRICE</th>
                  <th className={style.header_number}>SUBTOTAL</th>
                </tr>
              </thead>
              <tbody>{lines}</tbody>
            </table>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={20} />
          <Col span={4}>
            <table className={style.table}>
              <tbody>
                <tr className={style.row}>
                  <td>Sub Total</td>
                  <td className={style.total}>{toPrice(o.subTotal)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Shipping Amt</td>
                  <td className={style.total}>{toPrice(o.shippingAmt)}</td>
                </tr>
                {o.discountAmt > 0 && (
                  <tr className={style.row}>
                    <td>Discount</td>
                    <td className={style.total}>{toPrice(o.discountAmt)}</td>
                  </tr>
                )}
                <tr className={style.row}>
                  <td>Tax</td>
                  <td className={style.total}>{toPrice(o.taxes)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Total</td>
                  <td className={style.total}>{toPrice(o.total)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        {o.backOrders.length > 0 && (
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <h2>Back order items</h2>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <table className={style.table}>
                  <thead>
                    <tr className={style.row}>
                      <th className={style.header}>#</th>
                      <th className={style.header}>Product Name</th>
                      <th className={style.header_number}>Ordered Qty</th>
                      <th className={style.header_number}>Shipped Qty</th>
                      <th className={style.header_number}>Backorder</th>
                    </tr>
                  </thead>
                  <tbody>
                    {o.backOrders.map((item, key) => (
                      <tr key={key} className={style.row}>
                        <td className={style.product}> {key + 1}</td>
                        <td className={style.product}> {item.name}</td>
                        <td className={style.number}> {item.qtyOrdered}</td>
                        <td className={style.total}> {item.qtyInvoiced}</td>
                        <td className={style.total}> {item.qtyOrdered - item.qtyInvoiced}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  render() {
    let invoice = this.props.invoice;
    let lines = this.loadProducts(invoice.items);
    let content;
    if (invoice.code) {
      content = (
        <div className="cuiContentWrapper">
          <Navbar title={`View Invoice ${invoice.code}`}>
            <Button
              href={wrapUrlWithAuthToken(`/api/v1/invoice/print/?id=${invoice.id}`)}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex="-1"
            >
              Print Invoice
            </Button>
          </Navbar>
          <div>{this.generateInvoiceView(invoice, lines)}</div>
        </div>
      );
    } else {
      content = <Spinner />;
    }
    return <div id="print-mount">{content}</div>;
  }
}

const mapDispatchToProps = {
  findByUid: invoiceActions.findOne
};

const mapStateToProps = (state, ownProps) => ({
  invoice: invoiceSelectors.get(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceView);
