import React, { Component } from 'react';
import { TextFilter, OptionFilter, NumberFilter, BooleanFilter, DateFilter } from './FilterOptions';
import { Form, Row, Col, Button } from 'antd';

class FilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: this.props.filters
    };
  }

  onSearch = () => {
    var validArr = this.state.filters.filter(f => {
      return f.value || f.values;
    });

    var filterParams = {};
    validArr.map(
      item =>
        (filterParams[item.field] = {
          _field: item.field,
          value: item.value,
          operator: item.operator,
          values: item.values
        })
    );
    this.props.onSearch(filterParams);
  };

  onClearForm = () => {
    let clearedFilters = this.state.filters.map((item, key) => {
      item.value = '';
      return item;
    });
    this.setState({
      filters: clearedFilters
    });
    this.props.onClear();
    this.props.form.resetFields();
  };

  onFilter = state => {
    var obj = this.state.filters.find(i => i.field === state.field);
    if (state.value || state.values) {
      obj.value = state.value;
      obj.operator = state.operator;
      obj.values = state.values;
      this.setState(prevState => ({
        ...prevState
      }));
    } else {
      this.setState({
        filters: this.state.filters.filter((_, i) => i.field !== state.field)
      });
    }
  };

  render() {
    let items = this.state.filters.map((item, key) => {
      switch (item.type) {
        case 'OPTION':
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <OptionFilter
                form={this.props.form}
                options={item.options}
                filter={item}
                field={item.field}
                operator={item.operator}
                value={item.value}
                onFilter={this.onFilter}
              ></OptionFilter>
            </Col>
          );
        case 'NUMBER':
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <NumberFilter
                form={this.props.form}
                filter={item}
                field={item.field}
                operator={item.operator}
                value={item.value}
                onFilter={this.onFilter}
              ></NumberFilter>
            </Col>
          );
        case 'YESNO':
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <BooleanFilter
                form={this.props.form}
                filter={item}
                field={item.field}
                operator={item.operator}
                value={item.value}
                onFilter={this.onFilter}
              ></BooleanFilter>
            </Col>
          );
        case 'DATE':
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <DateFilter
                form={this.props.form}
                filter={item}
                field={item.field}
                operator={item.operator}
                value={item.value}
                onFilter={this.onFilter}
              ></DateFilter>
            </Col>
          );
        default:
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <TextFilter
                form={this.props.form}
                filter={item}
                field={item.field}
                operator={item.operator}
                value={item.value}
                onFilter={this.onFilter}
              ></TextFilter>
            </Col>
          );
      }
    });
    return (
      <Row gutter={24} style={{ padding: 16 }}>
        <Form id="filterForm" onSubmit={this.onSubmit}>
          {items}
          <Col span={24} style={{ float: 'right' }}>
            <div style={{ float: 'right' }}>
              <Button type="primary" htmlType="submit" onClick={this.onSearch}>
                Search
              </Button>
              <span>&nbsp;&nbsp;</span>
              <Button onClick={this.onClearForm}>Clear</Button>
            </div>
          </Col>
        </Form>
      </Row>
    );
  }
}

export default Form.create({ name: '' })(FilterForm);
