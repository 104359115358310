import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import style from './minicart.module.less';

class MiniCart extends Component {
  viewCart = () => this.props.history.push('/viewCart');
  render() {
    return (
      <div className={style.MiniCart}>
        <div className={style.miniCartContainer} onClick={this.viewCart}>
          <div className={style.icon}>
            <FontAwesomeIcon icon="shopping-cart" />
          </div>
          <div className={style.cartItems}>
            <p className={style.title}>Shopping Cart</p>
            <p className={style.itemCounts}>
              {this.props.itemCount} items - ${this.props.total}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MiniCart);
