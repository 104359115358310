import React, { Component } from 'react';
import { Carousel } from 'antd';
import styles from './carousel.module.less';

class CarouselComp extends Component {
  componentWillMount() {}

  componentWillUnmount() {}

  render() {
    let items = this.props.pages.map((item, key) => (
      <div key={key} className={styles.slickslide}>
        <a href={item.forwardLink} title={item.title} rel="noopener noreferrer" target="_blank">
          <img src={item.asset.path} alt={item.alt} className={styles.slideImg} />
        </a>
      </div>
    ));

    return (
      <div>
        <Carousel autoplay className={styles.carousel} dotPosition="bottom">
          {items}
        </Carousel>
      </div>
    );
  }
}

export default CarouselComp;
