import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { ProductLoading } from '../';
import style from './productloadinglist.module.less';

export default class ProductLoadingList extends Component {
  render() {
    let cols = [...Array(this.props.total)].map((x, idx) => (
      <Col span={24 / this.props.maxPerRow} key={idx}>
        <ProductLoading />
      </Col>
    ));
    let rowGutter = { xs: 8, sm: 16, md: 24 };
    return (
      <Row gutter={rowGutter}>
        <Col span={24}>
          <div className={style.name} />
        </Col>
        {cols}
      </Row>
    );
  }
}
