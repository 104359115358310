import React, { Component } from 'react';
import { Spinner } from 'components';
import DeviceBrowser from './components/DeviceBrowser/devicebrowser';
import { connect } from 'react-redux';
import * as attributesSelectors from 'redux/selectors/attributes';
import * as attributesActions from 'redux/actions/attributes';
import styles from './phonebrand.module.less';

class PhoneBrand extends Component {
  componentDidMount() {
    this.props.loadPhonesByBrandUrl(this.props.brandUrl);
  }

  componentWillUnmount() {
    this.props.unloadPhones();
  }

  render() {
    let content;
    if (this.props.arePhonesLoaded) {
      content = <DeviceBrowser devices={this.props.phonesList} />;
    } else {
      content = <Spinner />;
    }
    return (
      <div className="cuiContentWrapper">
        <div className={styles.phonesBox}>
          <h2 className="page-title">Phones</h2>
          {content}
        </div>
      </div>
    );
  }
}

class PhoneBrandWrapper extends Component {
  render() {
    return <PhoneBrand key={this.props.brandUrl} {...this.props} />;
  }
}

const mapDispatchToProps = {
  loadPhonesByBrandUrl: attributesActions.loadPhonesByBrandUrl,
  unloadPhones: attributesActions.unloadPhones
};

const convertToAttributes = function(list) {
  return list.map(l =>
    Object.assign({}, l, {
      asset: l.asset,
      title: l.name,
      url: `/phone/${l.url}`,
      count: l.count
    })
  );
};

const mapStateToProps = (state, ownProps) => ({
  phonesList: convertToAttributes(attributesSelectors.getPhonesList(state)),
  arePhonesLoaded: attributesSelectors.arePhonesLoaded(state),
  brandUrl: ownProps.match.params.brandUrl
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneBrandWrapper);
