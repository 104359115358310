import { call, takeLatest, all, put } from 'redux-saga/effects';
import { INVOICES_SEARCH, INVOICE_VIEW, INVOICE_VIEW_LOADED } from 'redux/actions/invoice/action_types';
import * as invoiceService from 'redux/services/invoice';
import * as listActions from 'redux/actions/list';

function* searchInvoices() {
  yield takeLatest(INVOICES_SEARCH, function*(action) {
    let orderResponse = yield call(invoiceService.searchInvoices, action.payload);
    yield put(listActions.listLoaded(orderResponse));
  });
}

function* findOne() {
  yield takeLatest(INVOICE_VIEW, function*(action) {
    let invResponse = yield call(invoiceService.findOne, action.payload);
    yield put({
      type: INVOICE_VIEW_LOADED,
      payload: invResponse
    });
  });
}

export default function* InvoiceSagas() {
  yield all([searchInvoices(), findOne()]);
}
