// @flow
import React, { Component } from 'react';
import { Row, Col, Input, Button, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import style from './footer.module.less';

export default class Footer extends Component {
  render() {
    return (
      <footer className={style.footer}>
        {false && (
          <div className={style.newsletterSubscribe}>
            <div className="cuiContentWrapper">
              <Form layout="inline">
                <Form.Item className={style.formItem}>
                  <span className={style.subscribeText}>Subscribe to our newsletters:</span>
                </Form.Item>
                <Form.Item className={style.formItem}>
                  <Input type="email" name="EMAIL" placeholder="Enter your email here" size="large" />
                </Form.Item>
                <Form.Item className={style.formItem}>
                  <Button className={style.subscribeBtn} size="large" type="primary">
                    <FontAwesomeIcon icon="envelope" /> Subscribe
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
        <div className={style.sitemap}>
          <div className="cuiContentWrapper">
            <Row>
              <Col span={7} className={style.sitemapcol}>
                <h3>Information</h3>
                <ul>
                  <li>
                    <Link to="/page/about-us/">
                      <FontAwesomeIcon icon="angle-double-right" />
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/page/shipping-policy/">
                      <FontAwesomeIcon icon="angle-double-right" />
                      Shipping Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/page/terms-of-use/">
                      <FontAwesomeIcon icon="angle-double-right" />
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link to="/page/privacy-policy/">
                      <FontAwesomeIcon icon="angle-double-right" />
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col span={7} className={style.sitemapcol}>
                <h3>Customer Service</h3>
                <ul>
                  <li>
                    <Link to="/">
                      <FontAwesomeIcon icon="angle-double-right" />
                      Home
                    </Link>
                  </li>
                  {this.props.isUserLoggedIn && (
                    <li>
                      <Link to="/customer/my-account/">
                        <FontAwesomeIcon icon="angle-double-right" />
                        My Account
                      </Link>
                    </li>
                  )}
                </ul>
              </Col>
              <Col span={10} className={style.sitemapcol}>
                <h3>Contact Us</h3>
                <ul className={style.contactDetails}>
                  <li>
                    <FontAwesomeIcon icon="map-marked-alt" />
                    <p>Address: 111 Esna Park Drive #3 Markham, Ontario L3R1H2</p>
                    <span className="clear-float" />
                  </li>
                  <li>
                    <FontAwesomeIcon icon="envelope-open" />
                    <p>Email: info@wplus.ca</p>
                    <span className="clear-float" />
                  </li>
                  <li>
                    <FontAwesomeIcon icon="phone" />
                    <p>Phone: +1 (905) 479-4000</p>
                    <span className="clear-float" />
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <div className={style.footerCopyright}>
          <div className="cuiContentWrapper">
            <Row>
              <Col span={24}>Wplus &copy; 2019. All Rights Reserved.</Col>
            </Row>
          </div>
        </div>
      </footer>
    );
  }
}
