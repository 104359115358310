import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Spinner, Product } from 'components';
import { createChunks } from 'Utils/utils';
import { Subject } from 'rxjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './productlist.module.less';

export default class ProductList extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.subject = new Subject();
  }

  componentWillUnmount() {
    this.subject.complete();
  }

  productChunks = () => {
    return createChunks(this.props.products, this.props.maxPerRow);
  };

  addToCart = () => {
    this.props.addProductToCart();
    this.subject.next('resetForm');
  };

  render() {
    let rowGutter = { xs: 8, sm: 16, md: 24 };
    let noProducts = (
      <Row>
        <Col span={24}>
          <div className={style.noProducts}>Cannot find any products with matching criteria.</div>
        </Col>
      </Row>
    );
    if (this.props.productsLoading) {
      noProducts = (
        <Row>
          <Col span={24}>
            <Spinner />
          </Col>
        </Row>
      );
    } else if (this.props.products.length > 0) {
      noProducts = (
        <Row>
          <Col span={24}>
            <div />
          </Col>
        </Row>
      );
    }
    let cols = this.productChunks().map((x, idx) => (
      <Row gutter={rowGutter} key={idx}>
        {x.map((p, pidx) => (
          <Col span={24 / this.props.maxPerRow} key={pidx}>
            <Product
              product={p}
              alreadyInCart={this.props.alreadyInCart}
              showPrices={this.props.showPrices}
              addToBag={this.props.addProductToBag}
              addToCart={this.addToCart}
              skipBag={this.props.skipBag}
              subject={this.subject}
              unloadSingleProduct={this.props.unloadSingleProduct}
              singleProduct={this.props.singleProduct}
              loadSingleProduct={this.props.loadSingleProduct}
            />
          </Col>
        ))}
      </Row>
    ));

    return (
      <div ref={this.containerRef}>
        {this.props.title && (
          <Row gutter={rowGutter}>
            <Col span={24}>
              <div className={style.name}>{this.props.title}</div>
            </Col>
          </Row>
        )}
        {cols}
        {noProducts}
        {!this.props.skipBag && this.props.showPrices && (
          <div className={style.floatingCart} title="Add items to cart" onClick={this.addToCart}>
            <div className={style.icon}>
              <FontAwesomeIcon icon="shopping-cart" />
            </div>
            <div className={style.text}>Add to cart</div>
          </div>
        )}
      </div>
    );
  }
}
