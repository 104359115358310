import { PAGE_LOAD, PAGE_LOADED } from './action_types';

const pageLoad = payload => ({
  type: PAGE_LOAD,
  payload: payload
});

const pageLoaded = payload => ({
  type: PAGE_LOADED,
  payload: payload
});

export { pageLoaded, pageLoad };
