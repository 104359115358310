import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import {
  CHECKOUT_LOAD_PAYMENT_METHODS,
  CHECKOUT_LOAD_SHIPPING_METHODS,
  CHECKOUT_UPDATE_SHIPPING_METHOD,
  CHECKOUT_UPDATE_PAYMENT_METHOD,
  CHECKOUT_CART
} from 'redux/actions/checkout/action_types';
import * as checkoutService from 'redux/services/checkout';
import * as checkoutActions from 'redux/actions/checkout';
import * as cartSelectors from 'redux/selectors/cart';
import * as userSelectors from 'redux/selectors/user';
import * as cartActions from 'redux/actions/cart';

function* fetchPaymentMethods() {
  yield takeLatest(CHECKOUT_LOAD_PAYMENT_METHODS, function*(action) {
    let methods = yield call(checkoutService.fetchPaymentMethods);
    yield put(checkoutActions.loadedPaymentMethods(methods));
  });
}

function* fetchShippingMethods() {
  yield takeLatest(CHECKOUT_LOAD_SHIPPING_METHODS, function*(action) {
    let methods = yield call(checkoutService.fetchShippingMethods);
    yield put(checkoutActions.loadedShippingMethods(methods));
  });
}

function* updateShippingMethod() {
  yield takeLatest(CHECKOUT_UPDATE_SHIPPING_METHOD, function*(action) {
    let cartId = yield select(cartSelectors.getCartId);
    let custId = yield select(userSelectors.getUserId);
    let data = Object.assign({}, action.payload, { customerId: custId, cartId: cartId });
    let cart = yield call(checkoutService.updateShippingMethod, data);
    yield put(checkoutActions.shippingMethodUpdated(cart));
  });
}

function* updatePaymentMethod() {
  yield takeLatest(CHECKOUT_UPDATE_PAYMENT_METHOD, function*(action) {
    let cartId = yield select(cartSelectors.getCartId);
    let custId = yield select(userSelectors.getUserId);
    let data = Object.assign({}, action.payload, { customerId: custId, cartId: cartId });
    let cart = yield call(checkoutService.updatePaymentMethod, data);
    yield put(checkoutActions.paymentMethodUpdated(cart));
  });
}

function* checkoutCart() {
  yield takeLatest(CHECKOUT_CART, function*(action) {
    let cartId = yield select(cartSelectors.getCartId);
    let miniCart = yield call(checkoutService.checkout, cartId);
    yield put(cartActions.minicartLoaded(miniCart));
    yield put(checkoutActions.checkoutSuccess());
  });
}

export default function* checkoutSagas() {
  yield all([
    fetchPaymentMethods(),
    fetchShippingMethods(),
    updateShippingMethod(),
    updatePaymentMethod(),
    checkoutCart()
  ]);
}
