import { fetchApi } from 'Utils/utils';

let fetchAddresses = function(customerId) {
  return fetchApi.get(`/api/v1/address/customer/${customerId}/`);
};

let fetchProvinces = function() {
  return fetchApi.get('/api/v1/address/getProvinces/');
};

let save = function(payload) {
  return fetchApi.post('/api/v1/address/', payload);
};

let update = function(payload) {
  return fetchApi.put(`/api/v1/address/${payload.id}/`, payload);
};

let deleteAddress = function(payload) {
  return fetchApi.put(`/api/v1/address/delete/${payload.id}/`, {});
};

export { fetchAddresses, fetchProvinces, save, update, deleteAddress };
