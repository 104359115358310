// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Alert } from 'antd';
import { withEventBus } from 'context/eventbus';
import { LoginForm } from 'components';
import * as userActions from 'redux/actions/user';
import * as homepageSelectors from 'redux/selectors/homepage';
import * as homepageActions from 'redux/actions/homepage';
import * as userSelectors from 'redux/selectors/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './login.module.less';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentWillUnmount() {
    this.props.unsetMessages();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onCancel = () => this.setState({ modalVisible: false });

  onLoginBtnClick = () => this.setState({ modalVisible: true });

  onOk = () => {};

  onRegister = () => {
    this.props.history.push('/customer/my-account/register/');
    this.onCancel();
  };

  onForgetPassword = () => {
    this.props.history.push('/customer/forget-password/');
    this.onCancel();
  };

  render() {
    if (this.props.isUserLoggedIn) {
      return (
        <div>
          <button className={style.logoutBtn} onClick={this.props.logoutBtnClickHandler}>
            Logout <FontAwesomeIcon icon="sign-out-alt" />
          </button>
        </div>
      );
    } else {
      return (
        <div className="cuiContentWrapper">
          <Row gutter={24}>
            <Col offset={7} span={10}>
              <h1 className="page-title">Customer Login</h1>
            </Col>
          </Row>
          {this.props.messages.errorMsg && (
            <Row gutter={24}>
              <Col offset={7} span={10}>
                <Alert message={this.props.messages.errorMsg} type="error" />
              </Col>
            </Row>
          )}
          <Row gutter={24} className={style.container}>
            <Col offset={7} span={10}>
              <LoginForm
                onRegister={this.onRegister}
                onForgetPassword={this.onForgetPassword}
                loginBtnClickHandler={this.props.loginBtnClickHandler}
                userLoginErrMsg={this.props.userLoginErrMsg}
              />
            </Col>
          </Row>
        </div>
      );
    }
  }
}

const mapDispatchToProps = {
  loginBtnClickHandler: userActions.userLogin,
  unsetMessages: homepageActions.unsetMessages
};

const mapStateToProps = (state, props) => {
  return {
    isUserLoggedIn: userSelectors.isUserLoggedIn(state),
    userLoginErrMsg: userSelectors.getErrMsg(state),
    messages: homepageSelectors.getMessages(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(withRouter(LoginPage)));
