import { fetchApi } from 'Utils/utils';

let fetchPhoneBrands = function() {
  return fetchApi.get('/api/v1/attribute/phoneBrands/');
};

let fetchPhonesByBrandUrl = function(brandUrl) {
  return fetchApi.get(`/api/v1/attribute/phoneBrands/${brandUrl}/`);
};

export { fetchPhoneBrands, fetchPhonesByBrandUrl };
