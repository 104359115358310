import React, { Component } from 'react';
import { Attribute } from './components';
import { Row, Col } from 'antd';
import { createChunks } from 'Utils/utils';
import styles from './attributebrowser.module.less';

const MAX_ATTRIBUTES_PER_ROW = 8;

export default class AttributeBrowser extends Component {
  attributeChunks = () => createChunks(this.props.attributes, MAX_ATTRIBUTES_PER_ROW);

  render() {
    let rowGutter = { xs: 8, sm: 16, md: 24 };
    return (
      <div className={styles.attributeBrowserBox}>
        {this.attributeChunks().map((c, idx) => (
          <Row key={idx} gutter={rowGutter} type="flex" justify="start" align="middle">
            {c.map((a, aIdx) => (
              <Col key={aIdx} span={24 / MAX_ATTRIBUTES_PER_ROW}>
                <Attribute attribute={a} />
              </Col>
            ))}
          </Row>
        ))}
      </div>
    );
  }
}
