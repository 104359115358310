export const ADDRESS_LOAD = 'ADDRESS_LOAD';
export const ADDRESS_LOADED = 'ADDRESS_LOADED';
export const ADDRESS_UNLOAD = 'ADDRESS_UNLOAD';
export const ADDRESS_LOAD_PROVINCES = 'ADDRESS_LOAD_PROVINCES';
export const ADDRESS_LOADED_PROVINCES = 'ADDRESS_LOADED_PROVINCES';
export const ADDRESS_SAVE = 'ADDRESS_SAVE';
export const ADDRESS_UPDATE = 'ADDRESS_UPDATE';
export const ADDRESS_SAVED = 'ADDRESS_SAVED';
export const ADDRESS_DELETE = 'ADDRESS_DELETE';
export const ADDRESS_DELETED = 'ADDRESS_DELETED';
