import { fetchApi } from 'Utils/utils';

let searchInvoices = function(params) {
  return fetchApi.post('/api/v1/invoice/search/', params);
};

let findOne = function(id) {
  return fetchApi.get(`/api/v1/invoice/${id}/`);
};

export { searchInvoices, findOne };
