// @flow
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Search, MiniCart, Login } from './components';
import { MegaMenu } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import style from './header.module.less';

export default class Header extends Component {
  render() {
    return (
      <header className={style.Header}>
        {false && (
          <div className={style.blinkText}>
            Our day to day Business activity has resumed and we are open Monday to Friday from 12:00pm to 5:00pm EST.
            Thank you for your understanding and co-operation.
          </div>
        )}
        <div className={style.topNoticeBar}>
          <Row>
            <Col span={24}>
              Wplus is a wholesale provider of Electronics and Mobile Accessories. Please create an account with us only
              for wholesale/bulk purchases. Thank you!
            </Col>
          </Row>
        </div>
        <div className={style.accountInfoBar}>
          <div className="cuiContentWrapper">
            <Row>
              <Col span={24} className={style.accountInfoButtonsContainer}>
                <ul className={style.accountInfoButtons}>
                  {this.props.isUserLoggedIn && (
                    <li>
                      <Link to="/customer/my-account/" className={style.accountBtn}>
                        <FontAwesomeIcon icon="user" /> My Account
                      </Link>
                    </li>
                  )}
                  <li>
                    <Login
                      loginBtnClickHandler={this.props.loginBtnClickHandler}
                      isUserLoggedIn={this.props.isUserLoggedIn}
                      userName={this.props.userName}
                      userLoginErrMsg={this.props.userLoginErrMsg}
                      logoutBtnClickHandler={this.props.logoutBtnClickHandler}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <div className={style.logoContainer}>
          <div className="cuiContentWrapper">
            <Row>
              <Col span={4} offset={2} className={style.logo}>
                <Link to="/">
                  <img src={this.props.logo} alt="Wplus Logo" />
                </Link>
              </Col>
              <Col span={12} className={style.search}>
                <Search />
              </Col>
              <Col span={4} className={style.cart}>
                {this.props.showCart && <MiniCart itemCount={this.props.cartItemCount} total={this.props.cartTotal} />}
              </Col>
            </Row>
          </div>
        </div>
        <div className={style.navigationMenu}>
          <div className="cuiContentWrapper">
            <Row>
              <Col span={24}>
                <MegaMenu items={this.props.menu} />
              </Col>
            </Row>
          </div>
        </div>
      </header>
    );
  }
}
