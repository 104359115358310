import {
  PRODUCTS_LOAD,
  PRODUCTS_LOADED,
  PRODUCTS_UNLOAD,
  PRODUCTS_UPDATE_FILTERS,
  PRODUCTS_FILTERS_LOAD,
  PRODUCTS_FILTERS_LOADED,
  PRODUCTS_LOAD_NEXT_PAGE,
  PRODUCTS_LOAD_PAGE_COUNT,
  PRODUCTS_PAGE_COUNT_LOADED,
  PRODUCTS_TOGGLE_FILTER,
  PRODUCTS_REFINE_BY_FILTERS,
  PRODUCTS_SINGLE_LOAD,
  PRODUCTS_SINGLE_LOADED,
  PRODUCTS_UNLOAD_SINGLE_PRODUCT,
  PHONE_LATEST_LOAD,
  PHONE_LATEST_LOADED
} from './action_types';

const loadProducts = () => ({
  type: PRODUCTS_LOAD,
  payload: {}
});

const unloadProducts = () => ({
  type: PRODUCTS_UNLOAD,
  payload: {}
});

const updateProductFilters = payload => ({
  type: PRODUCTS_UPDATE_FILTERS,
  payload: payload
});

const productsLoaded = payload => ({
  type: PRODUCTS_LOADED,
  payload: payload
});

const updateFiltersAndLoadProducts = payload => [updateProductFilters(payload)].concat(reloadProducts());

const productsRefineByFilters = () =>
  [
    {
      type: PRODUCTS_REFINE_BY_FILTERS,
      payload: {}
    }
  ].concat(reloadProducts());

const reloadProducts = () => [
  {
    type: PRODUCTS_LOAD_PAGE_COUNT,
    payload: {}
  },
  loadProducts()
];

const productFiltersLoad = payload => ({
  type: PRODUCTS_FILTERS_LOAD,
  payload: payload
});

const productFiltersLoaded = payload => ({
  type: PRODUCTS_FILTERS_LOADED,
  payload: payload
});

const productsLoadNextPage = () => [
  {
    type: PRODUCTS_LOAD_NEXT_PAGE,
    payload: {}
  },
  loadProducts()
];

const productsPageCountLoaded = payload => ({
  type: PRODUCTS_PAGE_COUNT_LOADED,
  payload: payload
});

const productsToggleFilter = (name, value) => ({
  type: PRODUCTS_TOGGLE_FILTER,
  payload: {
    name: name,
    value: value
  }
});

const loadProduct = (productId, variantId) => ({
  type: PRODUCTS_SINGLE_LOAD,
  payload: {
    productId: productId,
    variantId: variantId
  }
});

const productLoaded = payload => ({
  type: PRODUCTS_SINGLE_LOADED,
  payload: payload
});

const latestPhoneLoad = limit => ({
  type: PHONE_LATEST_LOAD,
  payload: limit
});

const latestPhoneLoaded = payload => ({
  type: PHONE_LATEST_LOADED,
  payload: payload
});

const unloadSingleProduct = () => ({
  type: PRODUCTS_UNLOAD_SINGLE_PRODUCT,
  payload: {}
});

export {
  loadProducts,
  unloadProducts,
  updateProductFilters,
  productsLoaded,
  updateFiltersAndLoadProducts,
  productFiltersLoad,
  productFiltersLoaded,
  productsLoadNextPage,
  productsPageCountLoaded,
  productsToggleFilter,
  productsRefineByFilters,
  loadProduct,
  productLoaded,
  latestPhoneLoaded,
  latestPhoneLoad,
  unloadSingleProduct
};
