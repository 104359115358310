// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Row, Col, Input, Button, Alert } from 'antd';
import { withEventBus } from 'context/eventbus';
import * as customerActions from 'redux/actions/customer';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/validationHelper';
import { CUSTOMER_SUMBIT_OK } from 'redux/actions/customer/action_types';
import styles from './resetPasswrod.module.less';

class ResetPasswordByCode extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      status: null,
      globalErrors: []
    };
  }

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(CUSTOMER_SUMBIT_OK, action => {
        this.setState({
          status: action
        });
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  resetPasswordHandler = value => {
    this.setState({
      status: null,
      globalErrors: []
    });
    this.props.resetPasswordByCode(value);
  };

  render() {
    return (
      <div className="cuiContentWrapper">
        <Row gutter={24}>
          <Col offset={6} span={12}>
            <h2 className="page-title">Reset your password</h2>
          </Col>
        </Row>
        {this.state.status && (
          <Row gutter={24}>
            <Col offset={6} span={12}>
              <Alert message="Password reset successful, Please login." type="success" />
            </Col>
          </Row>
        )}
        {this.state.globalErrors.length > 0 && (
          <Row gutter={24}>
            <Col offset={6} span={12}>
              <Alert message={this.state.globalErrors.join(', ')} type="error" />
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col offset={6} span={12}>
            <ResetPasswordByCodeForm
              code={this.props.match.params.code}
              eventBusSubscribe={this.props.eventBusSubscribe}
              resetPassword={this.resetPasswordHandler}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const ResetPasswordByCodeForm = Form.create({})(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        globalErrors: []
      };
    }

    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    };

    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.resetPassword(values);
        }
      });
    };

    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Row gutter={24}>
            <Col span={8}>
              {getFieldDecorator('code', {
                rules: [],
                initialValue: this.props.code
              })(<Input type="hidden" placeholder="code" />)}
              <Form.Item label="Password" className={styles.row}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please re-confirm your password!'
                    }
                  ]
                })(<Input.Password placeholder="Password" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Confirm Password" className={styles.row}>
                {getFieldDecorator('confirmPassword', {
                  rules: [
                    {
                      required: true,
                      message: 'Please re-confirm your password!'
                    },
                    {
                      validator: this.compareToFirstPassword
                    }
                  ]
                })(<Input.Password placeholder="Confirm Password" />)}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className={styles.row}>
            <Button type="primary" htmlType="submit">
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      );
    }
  }
);

const mapDispatchToProps = {
  resetPasswordByCode: customerActions.resetPasswordByCode
};

const mapStateToProps = (state, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(withRouter(ResetPasswordByCode)));
