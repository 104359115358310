import React, { Component } from 'react';
import { ProductLoadingList, CarouselComp, PhoneSlider } from 'components';
import { ProductsWidget, StaticImagesWidget } from './components';
import { connect } from 'react-redux';
import * as homepageSelectors from 'redux/selectors/homepage';
import * as cartSelectors from 'redux/selectors/cart';
import * as homepageActions from 'redux/actions/homepage';
import * as userSelectors from 'redux/selectors/user';
import * as cartActions from 'redux/actions/cart';
import * as productSelectors from 'redux/selectors/product';
import * as productActions from 'redux/actions/product';
import { Alert, notification } from 'antd';
import { withEventBus, withScreenLoader } from 'context';
import { CART_ADD_TO_CART_SUCCESS, CART_ADD_TO_CART_IGNORED } from 'redux/actions/cart/action_types';
import styles from './homepage.module.less';

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentWillMount() {
    if (!this.props.isHomepageContentLoaded) {
      this.props.loadHomepageContent();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(CART_ADD_TO_CART_SUCCESS, action => {
        this.props.hideScreenLoader();
        notification['success']({
          message: 'Added to cart',
          description: 'All products are added to cart successfully.',
          duration: 2.5
        });
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(CART_ADD_TO_CART_IGNORED, action => {
        this.props.hideScreenLoader();
      })
    );
  }

  addProductToCart = () => {
    this.props.showScreenLoader();
    this.props.addProductToCart();
  };

  componentWillUnmount() {
    this.props.unsetMessages();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  renderWidgets = () => {
    return this.props.widgets.map((w, idx) => {
      switch (w.type) {
        case 'SELECTED_PRODUCTS':
        case 'LATEST_PRODUCTS':
          return (
            <ProductsWidget
              widget={w}
              key={idx}
              alreadyInCart={this.props.alreadyInCart}
              showPrices={this.props.showPrices}
              addProductToBag={this.props.addProductToBag}
              addProductToCart={this.props.addProductToCart}
              loadSingleProduct={this.props.loadSingleProduct}
              unloadSingleProduct={this.props.unloadSingleProduct}
              singleProduct={this.props.singleProduct}
              skipBag={this.props.skipBag}
            />
          );
        case 'STATIC_IMAGES':
          return <StaticImagesWidget widget={w} key={idx} />;
        default:
          return <React.Fragment key={idx} />;
      }
    });
  };

  render() {
    let content;

    if (this.props.isHomepageContentLoaded) {
      content = this.renderWidgets();
    } else {
      content = (
        <div>
          <ProductLoadingList total={8} maxPerRow={4} />
          <ProductLoadingList total={8} maxPerRow={4} />
        </div>
      );
    }

    return (
      <div>
        <CarouselComp pages={this.props.imageSliderList} />
        <PhoneSlider slides={this.props.latestPhones} />
        <div className="cuiContentWrapper">
          {this.props.messages.errorMsg && (
            <Alert message={this.props.messages.errorMsg} type="error" showIcon className={styles.alertMsg} />
          )}
          {this.props.messages.infoMsg && (
            <Alert message={this.props.messages.infoMsg} type="info" showIcon className={styles.alertMsg} />
          )}
          {this.props.messages.warnMsg && (
            <Alert message={this.props.messages.warnMsg} type="warning" showIcon className={styles.alertMsg} />
          )}
          {this.props.messages.successMsg && (
            <Alert message={this.props.messages.successMsg} type="success" showIcon className={styles.alertMsg} />
          )}
          {content}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadHomepageContent: homepageActions.loadHomepageContent,
  addProductToBag: cartActions.addToBag,
  addProductToCart: cartActions.addToCart,
  unsetMessages: homepageActions.unsetMessages,
  loadSingleProduct: productActions.loadProduct,
  unloadSingleProduct: productActions.unloadSingleProduct
};

const mapStateToProps = (state, ownProps) => ({
  widgets: homepageSelectors.getWidgets(state),
  imageSliderList: homepageSelectors.getImageSliderList(state),
  latestPhones: homepageSelectors.getLatestPhonesList(state),
  isHomepageContentLoaded: homepageSelectors.isHomepageContentLoaded(state),
  showPrices: userSelectors.isUserLoggedIn(state),
  skipBag: true,
  messages: homepageSelectors.getMessages(state),
  alreadyInCart: cartSelectors.getAlreadyInCart(state),
  singleProduct: productSelectors.getSingleProduct(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(Homepage)));
