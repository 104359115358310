import { SUBCATEGORIES_LOADED, SUBCATEGORIES_UNLOAD } from 'redux/actions/category/action_types';

const initialStore = {
  subCategories: {
    loaded: false,
    list: []
  }
};

const subCategoriesLoaded = (state, payload) => {
  return Object.assign({}, state, {
    subCategories: {
      loaded: true,
      list: payload
    }
  });
};

const unloadSubCategories = (state, payload) =>
  Object.assign({}, state, {
    subCategories: initialStore.subCategories
  });

export default function categoryReducer(state = initialStore, action) {
  switch (action.type) {
    case SUBCATEGORIES_LOADED:
      return subCategoriesLoaded(state, action.payload);
    case SUBCATEGORIES_UNLOAD:
      return unloadSubCategories(state, action.payload);
    default:
      return state;
  }
}
