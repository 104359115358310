import { SUBCATEGORIES_LOAD, SUBCATEGORIES_UNLOAD } from './action_types';

const loadSubCategories = ids => ({
  type: SUBCATEGORIES_LOAD,
  payload: { ids: ids }
});

const unloadSubCategories = () => ({
  type: SUBCATEGORIES_UNLOAD,
  payload: {}
});

export { loadSubCategories, unloadSubCategories };
