import { MY_ORDERS_LOAD, MY_ORDERS_LOADED, VIEW_ORDER_LOAD, VIEW_ORDER_LOADED } from './action_types';

const watchSearchOrders = payload => ({
  type: MY_ORDERS_LOAD,
  payload: payload
});

const searchOrdersResult = payload => ({
  type: MY_ORDERS_LOADED,
  payload: payload
});

const watchfindOne = payload => ({
  type: VIEW_ORDER_LOAD,
  payload: payload
});

const findOne = payload => ({
  type: VIEW_ORDER_LOADED,
  payload: payload
});

export { watchSearchOrders, searchOrdersResult, watchfindOne, findOne };
