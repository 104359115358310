import { 
  USER_LOGIN, 
  USER_UPDATE_INFO, 
  USER_LOGIN_ERROR, 
  USER_LOGOUT, 
  USER_CLEAR_SESSION 
} from './action_types';

const userLogin = (username, password) => ({
  type: USER_LOGIN,
  payload: { username: username, password: password }
});

const updateUserInfo = payload => ({
  type: USER_UPDATE_INFO,
  payload: payload
});

const loginError = () => ({
  type: USER_LOGIN_ERROR,
  payload: {}
});

const userLogout = () => ({
  type: USER_LOGOUT,
  payload: {}
});

const resetSession = () => ({
  type: USER_CLEAR_SESSION,
  payload: {}
});

export { userLogin, updateUserInfo, loginError, userLogout, resetSession };
