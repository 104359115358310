import React from 'react';
import { Row, Col } from 'antd';
import styles from './widgets.module.less';

export default props => {
  let images = props.widget.images;
  let urls = props.widget.urls;
  let colspan = 24 / images.length;
  return (
    <div className={styles.widgetContainer}>
      {props.widget.displayTitle && (
        <Row gutter={24}>
          <Col span={24}>
            <div className={styles.title}>{props.widget.title}</div>
          </Col>
        </Row>
      )}
      <div className={styles.staticImagesContainer}>
        <Row gutter={24}>
          {images.map((i, idx) => (
            <Col span={colspan} key={idx}>
              {urls.length > idx && urls[idx] && (
                <a href={urls[idx]}>
                  <img src={i.path} alt="" className={styles.img} />
                </a>
              )}
              {urls.length <= idx && !urls[idx] && <img src={i.path} alt="" className={styles.img} />}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
