import React, { Component } from 'react';
import { Spinner, AttributeBrowser } from 'components';
import { connect } from 'react-redux';
import * as attributesSelectors from 'redux/selectors/attributes';
import * as attributesActions from 'redux/actions/attributes';
import styles from './devices.module.less';

class Devices extends Component {
  componentDidMount() {
    if (!this.props.areDevicesLoaded) {
      this.props.loadDevices();
    }
  }

  render() {
    let content;
    if (this.props.areDevicesLoaded) {
      content = <AttributeBrowser attributes={this.props.devicesList} />;
    } else {
      content = <Spinner />;
    }
    return (
      <div className="cuiContentWrapper">
        <div className={styles.devicesBox}>
          <h2 className="page-title">Devices</h2>
          {content}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadDevices: attributesActions.loadPhoneBrands
};

const convertToAttributes = function(list) {
  return list.map(l =>
    Object.assign({
      asset: l.asset,
      url: `/phoneBrand/${l.url}`,
      title: l.name,
      count: l.count
    })
  );
};

const mapStateToProps = state => ({
  devicesList: convertToAttributes(attributesSelectors.getPhoneBrandsList(state)),
  areDevicesLoaded: attributesSelectors.arePhoneBrandsLoaded(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Devices);
