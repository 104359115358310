import React, { Component } from 'react';
import styles from './productloading.module.less';

export default class ProductLoading extends Component {
  render() {
    return (
      <div className={styles.productLoadingContainer}>
        <div className={styles.boxImage} />
        <div className={styles.name} />
        <div className={styles.name} />
      </div>
    );
  }
}
