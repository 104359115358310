import {
  ADDRESS_LOAD,
  ADDRESS_LOADED,
  ADDRESS_UNLOAD,
  ADDRESS_LOAD_PROVINCES,
  ADDRESS_LOADED_PROVINCES,
  ADDRESS_SAVE,
  ADDRESS_SAVED,
  ADDRESS_UPDATE,
  ADDRESS_DELETE,
  ADDRESS_DELETED
} from './action_types';

const load = () => ({
  type: ADDRESS_LOAD,
  payload: {}
});

const loaded = payload => ({
  type: ADDRESS_LOADED,
  payload: payload
});

const unload = () => ({
  type: ADDRESS_UNLOAD,
  payload: {}
});

const loadProvinces = () => ({
  type: ADDRESS_LOAD_PROVINCES,
  payload: {}
});

const loadedProvinces = payload => ({
  type: ADDRESS_LOADED_PROVINCES,
  payload: payload
});

const save = payload => ({
  type: ADDRESS_SAVE,
  payload: payload
});

const update = payload => ({
  type: ADDRESS_UPDATE,
  payload: payload
});

const saved = payload => ({
  type: ADDRESS_SAVED,
  payload: payload
});

const deleteAdd = payload => ({
  type: ADDRESS_DELETE,
  payload: payload
});

const deleted = payload => ({
  type: ADDRESS_DELETED,
  payload: payload
});

export { load, loaded, unload, loadProvinces, loadedProvinces, save, saved, update, deleteAdd, deleted };
