export const CART_MINICART_LOAD = 'CART_MINICART_LOAD';
export const CART_MINICART_LOADED = 'CART_MINICART_LOADED';
export const CART_ADD_TO_BAG = 'CART_ADD_TO_BAG';
export const CART_ADD_TO_CART = 'CART_ADD_TO_CART';
export const CART_ADD_TO_CART_SUCCESS = 'CART_ADD_TO_CART_SUCCESS';
export const CART_ADD_TO_CART_IGNORED = 'CART_ADD_TO_CART_IGNORED';
export const CART_CLEAR_BAG = 'CART_CLEAR_BAG';
export const CART_LOAD = 'CART_LOAD';
export const CART_LOADED = 'CART_LOADED';
export const CART_UNLOAD = 'CART_UNLOAD';
export const CART_UPDATE_QTY = 'CART_UPDATE_QTY';
export const CART_REMOVE_ITEMS = 'CART_REMOVE_ITEMS';
export const CART_CLEAR = 'CART_CLEAR';
export const CART_IMAGE_LOAD = 'CART_IMAGE_LOAD';
export const CART_IMAGE_LOADED = 'CART_IMAGE_LOADED';
export const CART_APPLY_COUPON = 'CART_APPLY_COUPON';
export const CART_APPLY_COUPON_SUCCESS = 'CART_APPLY_COUPON_SUCCESS';
export const CART_APPLY_COUPON_ERROR = 'CART_APPLY_COUPON_ERROR';
export const CART_REORDER = 'CART_REORDER';
