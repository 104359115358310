import { combineReducers } from 'redux';
import configReducer from './config';
import homepageReducer from './homepage';
import attributesReducer from './attributes';
import categoryReducer from './category';
import productReducer from './product';
import userReducer from './user';
import cartReducer from './cart';
import addressReducer from './address';
import checkoutReducer from './checkout';
import customerReducer from './customer';
import orderReducer from './order';
import tableListReducer from './tableList';
import invoiceReducer from './invoice';
import pageReducer from './page';

const reducer = combineReducers({
  config: configReducer,
  homepage: homepageReducer,
  attributes: attributesReducer,
  category: categoryReducer,
  products: productReducer,
  user: userReducer,
  cart: cartReducer,
  address: addressReducer,
  checkout: checkoutReducer,
  customer: customerReducer,
  search: orderReducer,
  list: tableListReducer,
  invoice: invoiceReducer,
  page: pageReducer
});

export default reducer;
