import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProductBrowser } from 'components';
import * as productSelectors from 'redux/selectors/product';
import * as productActions from 'redux/actions/product';
import * as userSelectors from 'redux/selectors/user';
import * as cartActions from 'redux/actions/cart';
import * as cartSelectors from 'redux/selectors/cart';

class ProductsByPhone extends Component {
  render() {
    return (
      <div className="cuiContentWrapper">
        <ProductBrowser key={this.props.baseFilters.value} {...this.props} alreadyInCart={this.props.alreadyInCart} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  unloadProducts: productActions.unloadProducts,
  updateFiltersAndLoadProducts: productActions.updateFiltersAndLoadProducts,
  loadProductFilters: productActions.productFiltersLoad,
  loadNextPage: productActions.productsLoadNextPage,
  toggleFilter: productActions.productsToggleFilter,
  refineByFilters: productActions.productsRefineByFilters,
  addProductToBag: cartActions.addToBag,
  addProductToCart: cartActions.addToCart,
  loadSingleProduct: productActions.loadProduct,
  unloadSingleProduct: productActions.unloadSingleProduct
};

const mapStateToProps = (state, ownProps) => ({
  productsList: productSelectors.getProductsList(state),
  areProductsLoading: productSelectors.areProductsLoading(state),
  morePagesAvailable: productSelectors.morePagesAvailable(state),
  baseFilters: { value: ownProps.match.params.phoneUrl, listBy: 'PHONE' },
  showPrices: userSelectors.isUserLoggedIn(state),
  skipBag: false,
  title: productSelectors.getTitle(state),
  filters: productSelectors.getFilters(state),
  activeFilters: productSelectors.getActiveFilters(state),
  alreadyInCart: cartSelectors.getAlreadyInCart(state),
  singleProduct: productSelectors.getSingleProduct(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsByPhone);
