import React from 'react';
import { Input, Form, Select, Button, Row, Col, Checkbox } from 'antd';
import AddressSubForm from './addressSubForm';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/validationHelper';
import { CUSTOMER_SUMBIT_OK } from 'redux/actions/customer/action_types';
import styles from './customerForm.module.less';

class CreateCustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.saveCustomer(values);
      }
    });
  };

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );

    this.subscriptions.push(
      this.props.eventBusSubscribe(CUSTOMER_SUMBIT_OK, () => {
        this.props.form.resetFields();
      })
    );
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords do not match.');
    } else {
      callback();
    }
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <h1 className="page-title">Register New Account</h1>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <h3 className={styles.sectionHeader}>Personal Information</h3>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="First name" className={styles.row}>
              {getFieldDecorator('firstName', {
                rules: [{ required: true, message: 'First Name is required' }]
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Middle name" className={styles.row}>
              {getFieldDecorator('middleName', {
                rules: []
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Last name" className={styles.row}>
              {getFieldDecorator('lastName', {
                rules: [{ required: true, message: 'Last name is required' }]
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Preferred Doc Type" className={styles.row}>
              {getFieldDecorator('preferredDocType', {
                rules: [{ required: true, message: 'Select a Preferred Doc Type' }]
              })(
                <Select>
                  {[{ id: 'FULL_INVOICE', name: 'Full Invoice' }, { id: 'DISPATCH_NOTE', name: 'Dispatch Note' }].map(
                    p => (
                      <Select.Option key={p} value={p.id}>
                        {p.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Referred By" className={styles.row}>
              {getFieldDecorator('referredBy', {
                rules: []
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="HST/GST Number" className={styles.row}>
              {getFieldDecorator('hstNumber', {
                rules: [{ required: true, message: 'HST/GST Number is required' }]
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <h3 className={styles.sectionHeader}>Sign-in Information</h3>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Email" className={styles.row}>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'This is not valid E-mail' }
                ]
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Password" className={styles.row}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please re-confirm your password'
                  }
                ]
              })(<Input.Password />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Confirm Password" className={styles.row}>
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Please re-confirm your password'
                  },
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(<Input.Password />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <h3 className={styles.sectionHeader}>Address Information</h3>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <AddressSubForm
              loadProvinces={this.props.loadProvinces}
              provinces={this.props.provinces}
              form={this.props.form}
            />
          </Col>
        </Row>
        <Form.Item className={styles.row}>
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item>
                {getFieldDecorator('terms', {
                  rules: [
                    {
                      type: 'boolean',
                      validator: (rule, value, cb) => (value ? cb() : cb('Please review and accept our terms of use.'))
                    }
                  ]
                })(
                  <Checkbox>
                    I accept these{' '}
                    <a className={styles.hyperLink} href="/page/terms-of-use/" target="_new">
                      Terms &amp; Conditions
                    </a>{' '}
                    of usage.
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'customerCreateForm' })(CreateCustomerForm);
