import React, { Component } from 'react';
import { Checkbox, Button } from 'antd';
import styles from './filters.module.less';

export default class Filters extends Component {
  render() {
    let attrs = this.props.filters.attributes.map((a, aIdx) => {
      let attr = this.props.activeFilters.attributes.find(f => f.code === a.code);
      let active = attr ? attr.values || [] : [];
      if (a.code === 'phone') {
        let activePhoneBrands = this.props.activeFilters.phoneBrands || [];
        return (
          <React.Fragment>
            <PhoneAttr
              key={aIdx}
              phoneBrands={this.props.filters.phoneBrands}
              activePhoneBrands={activePhoneBrands}
              phones={a.values}
              toggleFilter={this.props.toggleFilter}
              refineByFilters={this.props.refineByFilters}
              phoneCode={a.code}
              phoneTitle={a.label}
              activePhones={active}
            />
          </React.Fragment>
        );
      } else {
        return (
          <FilterAttr
            key={aIdx}
            collection={a.values}
            active={active}
            valueKey="value"
            labelKey="label"
            name={a.code}
            title={a.label}
            toggleFilter={this.props.toggleFilter}
            refineByFilters={this.props.refineByFilters}
          />
        );
      }
    });
    return (
      <div>
        <div className={styles.title}>Shop By</div>
        <FilterAttr
          collection={this.props.filters.categories}
          active={this.props.activeFilters.categories}
          valueKey="id"
          labelKey="name"
          name="categories"
          title="Categories"
          toggleFilter={this.props.toggleFilter}
          refineByFilters={this.props.refineByFilters}
        />
        <FilterAttr
          collection={this.props.filters.products}
          active={this.props.activeFilters.products}
          valueKey="id"
          labelKey="name"
          name="products"
          title="Products"
          toggleFilter={this.props.toggleFilter}
          refineByFilters={this.props.refineByFilters}
        />
        {attrs}
      </div>
    );
  }
}

class PhoneAttr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePhoneBrands: []
    };
  }

  togglePhoneBrand = (name, value) => {
    if (this.state.activePhoneBrands.includes(value)) {
      this.setState({ activePhoneBrands: this.state.activePhoneBrands.filter(v => v !== value) });
      this.props.phones.forEach(phone => {
        if (this.props.activePhones.includes(phone.value) && phone.metadata === value) {
          this.props.toggleFilter(this.props.phoneCode, phone.value);
        }
      });
    } else {
      this.setState({ activePhoneBrands: this.state.activePhoneBrands.concat(value) });
    }
    this.props.toggleFilter(name, value);
  };

  render() {
    return (
      <div>
        <FilterAttr
          collection={this.props.phoneBrands}
          active={this.props.activePhoneBrands}
          valueKey="id"
          labelKey="name"
          name="phoneBrands"
          title="Phone Brand"
          toggleFilter={this.togglePhoneBrand}
          refineByFilters={this.props.refineByFilters}
          showSingleValue={true}
        />
        <FilterAttr
          collection={this.props.phones.filter(v => this.state.activePhoneBrands.includes(v.metadata))}
          active={this.props.activePhones}
          valueKey="value"
          labelKey="label"
          name={this.props.phoneCode}
          title={this.props.phoneTitle}
          toggleFilter={this.props.toggleFilter}
          refineByFilters={this.props.refineByFilters}
        />
      </div>
    );
  }
}

class FilterAttr extends Component {
  refineByFilters = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.props.refineByFilters();
  };

  clearFilters = () => {
    let active = this.props.collection.filter(c => this.props.active.includes(c[this.props.valueKey]));
    active.forEach(c => this.props.toggleFilter(this.props.name, c[this.props.valueKey]));
    this.refineByFilters();
  };

  render() {
    let selColComp = this.props.collection
      .filter(c => this.props.active.includes(c[this.props.valueKey]))
      .map((c, idx) => (
        <FilterCheckbox
          key={idx}
          value={c[this.props.valueKey]}
          label={c[this.props.labelKey]}
          values={this.props.active}
          name={this.props.name}
          toggleFilter={this.props.toggleFilter}
        />
      ));
    let selectedCnt = selColComp.length;
    let colComp = selColComp.concat(
      this.props.collection
        .filter(c => !this.props.active.includes(c[this.props.valueKey]))
        .map((c, idx) => (
          <FilterCheckbox
            key={idx + selectedCnt}
            value={c[this.props.valueKey]}
            label={c[this.props.labelKey]}
            values={this.props.active}
            name={this.props.name}
            toggleFilter={this.props.toggleFilter}
          />
        ))
    );
    return (
      <div>
        {(this.props.showSingleValue || colComp.length > 1) && (
          <div>
            <div className={styles.filterTitle}>{this.props.title}</div>
            <div className={styles.filterOptions}>{colComp}</div>
            <div className={styles.refineBtnContainer}>
              <Button className={styles.clearBtn} type="link" size="small" onClick={this.clearFilters}>
                Clear
              </Button>
              <Button className={styles.refineBtn} size="small" type="primary" onClick={this.refineByFilters}>
                Refine
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class FilterCheckbox extends Component {
  render() {
    let values = this.props.values;
    let value = this.props.value;
    let name = this.props.name;
    let label = this.props.label;
    return (
      <Checkbox
        checked={values.includes(value)}
        onChange={() => this.props.toggleFilter(name, value)}
        className={styles.filterCheckbox}
      >
        {label}
      </Checkbox>
    );
  }
}
