import { fetchApi } from 'Utils/utils';

let fetchPaymentMethods = function() {
  return fetchApi.get('/api/v1/checkout/payment/getMethods/');
};

let fetchShippingMethods = function() {
  return fetchApi.get('/api/v1/checkout/shipping/getMethods/');
};

let updateShippingMethod = function(payload) {
  return fetchApi.post('/api/v1/checkout/shipping/update/', payload);
};

let updatePaymentMethod = function(payload) {
  return fetchApi.post('/api/v1/checkout/payment/update/', payload);
};

let checkout = function(cartId) {
  return fetchApi.post(`/api/v1/checkout/${cartId}/`, {});
};

export { fetchPaymentMethods, fetchShippingMethods, updateShippingMethod, updatePaymentMethod, checkout };
