import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button } from 'antd';
import styles from './error.module.less';

export default class PageNotFound extends Component {
  render() {
    return (
      <div className="cuiContentWrapper">
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 12, offset: 6 }}>
            <div className={styles.errorPage}>
              <FontAwesomeIcon className={styles.icon} icon="exclamation-circle" />
              <p className={styles.message}>The page you are looking for cannot be found.</p>
              <Button className={styles.btn} type="primary" size="large" onClick={() => this.props.history.push('/')}>
                Go to Home
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
