// @flow
import React from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import style from './login.module.less';


class LoginForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.loginBtnClickHandler(values.username, values.password);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {this.props.userLoginErrMsg !== null && (
          <Alert message="Error" description={this.props.userLoginErrMsg} type="error" showIcon />
        )}
        <Form.Item className={style.row}>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }]
          })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />)}
        </Form.Item>
        <Form.Item className={style.row}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item className={style.row}>
          <Button type="primary" htmlType="submit" block className="login-form-button">
            Log in
          </Button>
        </Form.Item>
        <Form.Item className={style.row}>
          <Button.Group>
            <Button type="primary" ghost onClick={this.props.onForgetPassword}>
              Forgot Password
            </Button>
            <Button type="primary" onClick={this.props.onRegister} ghost>
              Register now!
            </Button>
          </Button.Group>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(LoginForm);
