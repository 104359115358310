import React, { Component } from 'react';
import { Affix, Table, Button, Alert, Card, Form, Input, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import * as utils from 'Utils/utils';
import styles from './cartsummary.module.less';
import { CART_APPLY_COUPON_ERROR, CART_APPLY_COUPON_SUCCESS } from 'redux/actions/cart/action_types';
import { withEventBus } from 'context';

class CartSummary extends Component {
  checkout = () => this.props.history.push('/checkout/shipping');

  getSummaryData = () => {
    let data = [
      {
        name: 'Subtotal',
        value: this.props.cart.subTotal
      },
      {
        name: 'Shipping',
        value: (() => <span className={styles.shippingCharges}>TBD later</span>)()
      }
    ];
    if (this.props.cart.totalDiscAmt > 0) {
      data.push({
        name:
          'Discount' +
          (this.props.cart.couponCode && this.props.cart.couponCode.trim() !== ''
            ? ` (${this.props.cart.couponCode})`
            : ''),
        value: this.props.cart.totalDiscAmt
      });
    }
    data.push({
      name: 'Taxes',
      value: this.props.cart.totalTaxAmt
    });
    data.push({
      name: 'Order Total',
      value: this.props.cart.total
    });
    return data;
  };

  render() {
    return (
      <div>
        <Card title="Summary" className={styles.summaryCard}>
          <Alert
            className={styles.shippingAlert}
            description={
              'Shipping charges will be added after order is processed. Your order will be processed ' +
              'within 24 working hours. Orders placed after 2:00 pm EST will be processed next working day.'
            }
            type="info"
          />
          <Table
            className={styles.summaryTable}
            rowKey="name"
            pagination={false}
            showHeader={false}
            dataSource={this.getSummaryData()}
            columns={[
              { title: 'name', dataIndex: 'name', key: 'name' },
              {
                title: 'value',
                dataIndex: 'value',
                key: 'value',
                className: (() => styles.summaryValue)(),
                render: text => (React.isValidElement(text) ? text : '$' + utils.toPrice(text))
              }
            ]}
          />
          {this.props.applyCoupon && <CouponForm applyCoupon={this.props.applyCoupon} cartId={this.props.cart.id} />}
        </Card>
        <Affix offsetTop={30}>
          {this.props.showCheckoutBtn && (
            <Button type="primary" block size="large" className={styles.checkoutBtn} onClick={this.checkout}>
              Proceed to checkout
            </Button>
          )}
        </Affix>
      </div>
    );
  }
}

const CouponForm = withEventBus(
  Form.create()(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          errors: []
        };
        this.subscriptions = [];
      }

      handleSubmit = e => {
        e.preventDefault();
        this.setState({
          errors: []
        });
        this.props.form.validateFields((err, values) => {
          if (values.couponCode) {
            this.props.applyCoupon(values);
          }
        });
      };

      componentDidMount() {
        this.subscriptions.push(
          this.props.eventBusSubscribe(CART_APPLY_COUPON_SUCCESS, action => {
            this.props.form.resetFields();
          })
        );
        this.subscriptions.push(
          this.props.eventBusSubscribe(CART_APPLY_COUPON_ERROR, action => {
            let globalErrors = action.payload.globalErrors;
            if (globalErrors.length > 0) {
              this.setState({
                errors: globalErrors
              });
            }
          })
        );
      }

      componentWillUnmount() {
        this.subscriptions.forEach(s => s.unsubscribe());
      }

      render() {
        const { getFieldDecorator } = this.props.form;
        return (
          <div className={styles.couponForm}>
            <Form onSubmit={this.handleSubmit}>
              {getFieldDecorator('cartId', {
                initialValue: this.props.cartId
              })(<Input type="hidden" />)}
              <Form.Item>
                <Row gutter={4}>
                  <Col span={18}>{getFieldDecorator('couponCode', {})(<Input placeholder="Enter coupon" />)}</Col>
                  <Col span={6}>
                    <Button type="default" htmlType="submit">
                      Apply
                    </Button>
                  </Col>
                </Row>
                {this.state.errors.length > 0 && (
                  <Row>
                    <Col span={24}>
                      <Alert type="error" description={this.state.errors.join(', ')} />
                    </Col>
                  </Row>
                )}
              </Form.Item>
            </Form>
          </div>
        );
      }
    }
  )
);

export default withRouter(CartSummary);
