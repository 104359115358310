import { CUSTOMER_LOADED } from 'redux/actions/customer/action_types';

const initialStore = {
  isLoaded: false,
  customer: {}
};

const loadView = (state, payload) => {
  return Object.assign({}, state, {
    isLoaded: true,
    customer: payload
  });
};

export default function customer(state = initialStore, action) {
  switch (action.type) {
    case CUSTOMER_LOADED:
      return loadView(state, action.payload);
    default:
      return state;
  }
}
