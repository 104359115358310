export const getUser = store => (store.user ? store.user : {});
export const isUserLoaded = store => {
  let user = getUser(store);
  return user.loaded != null && user.loaded;
};
export const getName = store => getUser(store).name;
export const getUserId = store => getUser(store).userId;
export const getRoleName = store => getUser(store).roleName;
export const isUserLoggedIn = store =>
  isUserLoaded(store) && getUserId(store) != null && getRoleName(store) === 'CUSTOMER';
export const getErrMsg = store => getUser(store).errMsg;
