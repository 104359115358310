import { fetchApi } from 'Utils/utils';

let addToCart = function(payload) {
  return fetchApi.post('/api/v1/cart/addToCart/', payload);
};

let loadCart = function(cartId) {
  return fetchApi.get(`/api/v1/cart/${cartId}/`);
};

let removeItems = function(payload) {
  return fetchApi.post('/api/v1/cart/removeItems/', payload);
};

let updateItemsQty = function(payload) {
  return fetchApi.post('/api/v1/cart/updateItemsQty/', payload);
};

let clear = function(cartId) {
  return fetchApi.put(`/api/v1/cart/clear/${cartId}/`, {});
};

let applyCoupon = function(payload) {
  return fetchApi.put('/api/v1/cart/applyCoupon/', payload);
};

let reorder = function(payload) {
  return fetchApi.post(`/api/v1/cart/${payload.cartId}/reorder/${payload.orderId}/`);
};

export { addToCart, loadCart, removeItems, updateItemsQty, clear, applyCoupon, reorder };
