import React from 'react';
import { Input, Form, Row, Col, Select } from 'antd';
import styles from './customerForm.module.less';

class AddressSubForm extends React.Component {
  componentDidMount() {
    this.props.loadProvinces();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item className={styles.row} label="Name">
            {getFieldDecorator('address.name', {
              rules: []
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className={styles.row} label="Company Name">
            {getFieldDecorator('address.company', {
              rules: [{ required: true, message: 'Company name is required' }]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className={styles.row} label="Street 1">
            {getFieldDecorator('address.street1', {
              rules: [{ required: true, message: 'Street 1 is required' }]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className={styles.row} label="Street 2">
            {getFieldDecorator('address.street2', {
              rules: []
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className={styles.row} label="City">
            {getFieldDecorator('address.city', {
              rules: [{ required: true, message: 'City is required' }]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className={styles.row} label="Postal Code">
            {getFieldDecorator('address.postalCode', {
              rules: [{ required: true, message: 'Postal Code is required' }]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className={styles.row} label="Province">
            {getFieldDecorator('address.provinceId', {
              rules: [{ required: true, message: 'Select a province' }]
            })(
              <Select>
                {this.props.provinces.map(p => (
                  <Select.Option key={p.id} value={p.id}>
                    {p.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className={styles.row} label="Phone No">
            {getFieldDecorator('address.phoneNo', {
              rules: [{ required: true, message: 'Phone number is required' }]
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(AddressSubForm);
