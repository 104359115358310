import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as addressActions from 'redux/actions/address';
import * as addressSelectors from 'redux/selectors/address';
import * as cartActions from 'redux/actions/cart';
import * as cartSelectors from 'redux/selectors/cart';
import * as checkoutActions from 'redux/actions/checkout';
import * as checkoutSelectors from 'redux/selectors/checkout';
import CheckoutSteps from 'pages/Checkout/steps';
import { Row, Col, Form, Radio, Button, Modal, Input } from 'antd';
import { CartSummary, AddressForm } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withEventBus, withScreenLoader } from 'context';
import { ADDRESS_SAVED } from 'redux/actions/address/action_types';
import { CHECKOUT_PAYMENT_METHOD_UPDATED } from 'redux/actions/checkout/action_types';
import { CART_LOADED } from 'redux/actions/cart/action_types';
import styles from './payment.module.less';
import cstyles from '../checkout.module.less';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddressForm: false,
      address: { id: 0 }
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.loadCart();
    this.props.loadAddresses();
    this.props.loadPaymentMethods();
    this.props.showScreenLoader();
    this.subscriptions.push(
      this.props.eventBusSubscribe(ADDRESS_SAVED, () => {
        this.toggleAddressForm();
        this.props.loadAddresses();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(CHECKOUT_PAYMENT_METHOD_UPDATED, () => {
        this.props.hideScreenLoader();
        this.props.history.push('/checkout/confirm');
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(CART_LOADED, () => {
        this.props.hideScreenLoader();
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unloadCart();
    this.props.unloadAddresses();
    this.props.unloadCheckout();
  }

  toggleAddressForm = () => {
    this.setState((state, props) => ({
      showAddressForm: !state.showAddressForm
    }));
  };

  saveNewAddress = add => {
    this.props.saveAddress(add);
  };

  render() {
    return (
      <div className="cuiContentWrapper">
        <div className={styles.paymentCheckout}>
          <Row gutter={24}>
            <Col span={18}>
              <CheckoutSteps current={1} />
              <WrappedPaymentForm
                addresses={this.props.addresses}
                cart={this.props.cart}
                paymentMethods={this.props.paymentMethods}
                updatePaymentMethod={this.props.updatePaymentMethod}
                addNewAddress={this.toggleAddressForm}
                showScreenLoader={this.props.showScreenLoader}
              />
            </Col>
            <Col span={6}>
              <CartSummary cart={this.props.cart} showCheckoutBtn={false} />
            </Col>
          </Row>
          <Modal
            title="Add new address"
            centered
            visible={this.state.showAddressForm}
            footer={null}
            onCancel={this.toggleAddressForm}
            width="850px"
            destroyOnClose={true}
          >
            <AddressForm
              data={this.state.address}
              loadProvinces={this.props.loadProvinces}
              provinces={this.props.provinces}
              saveAddress={this.saveNewAddress}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

class PaymentForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.showScreenLoader();
        this.props.updatePaymentMethod(values);
      }
    });
  };

  getInitialAddressValue = () => {
    return this.props.cart.billingAddress != null
      ? this.props.cart.billingAddress.id
      : this.props.cart.shippingAddress != null
      ? this.props.cart.shippingAddress.id
      : null;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className={styles.paymentForm} onSubmit={this.handleSubmit}>
        <h3 className={cstyles.sectionHeading}>Billing Address</h3>
        <Form.Item className={styles.addressItem}>
          {getFieldDecorator('addressId', {
            initialValue: this.getInitialAddressValue(),
            rules: [{ required: true, message: 'Please select a billing address or add new address.' }]
          })(
            <Radio.Group className={styles.addressRadioGroup}>
              {this.props.addresses.map(add => (
                <Radio value={add.id} key={add.id} className={styles.addressRadio}>
                  <div className={styles.addContainer}>
                    <span className={styles.addName}>{add.name}</span>
                    {add.company && <span className={styles.addLine}>{add.company}</span>}
                    <span className={styles.addLine}>{add.street1}</span>
                    {add.street2 && <span className={styles.addLine}>{add.street2}</span>}
                    <span className={styles.addLine}>
                      {add.city}, {add.province} {add.postalCode}
                    </span>
                    {add.phoneNo && <span className={styles.addLine}>{add.phoneNo}</span>}
                    <FontAwesomeIcon icon="check-square" className={styles.addSelectedIcon} />
                  </div>
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item className={styles.addNewAddBtnContainer}>
          <Button onClick={this.props.addNewAddress}>Add New</Button>
        </Form.Item>
        <h3 className={cstyles.sectionHeading}>Payment Method</h3>
        <Form.Item className={styles.methodItem}>
          {getFieldDecorator('method', {
            initialValue: this.props.cart.paymentMethod,
            rules: [{ required: true, message: 'Please select a payment method.' }]
          })(
            <Radio.Group className={styles.methodRadioGroup}>
              {this.props.paymentMethods.map(m => (
                <Radio value={m.id} key={m.id} className={styles.methodRadio}>
                  <span className={styles.methodName}>{m.name}</span>
                  <span className={styles.methodDesc}>{m.description}</span>
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item className={styles.methodItem}>
          {getFieldDecorator('comments', {
            initialValue: this.props.cart.comments
          })(<Input.TextArea className={styles.commentsInput} rows={4} placeholder="Any comments" />)}
        </Form.Item>
        <div className={cstyles.actionBtnContainer}>
          <Button className={cstyles.nextBtn} htmlType="submit" type="primary" size="large">
            Next
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedPaymentForm = Form.create({ name: 'paymentCheckoutForm' })(PaymentForm);

const mapDispatchToProps = {
  loadAddresses: addressActions.load,
  loadProvinces: addressActions.loadProvinces,
  unloadAddresses: addressActions.unload,
  loadCart: cartActions.load,
  unloadCart: cartActions.unload,
  loadPaymentMethods: checkoutActions.loadPaymentMethods,
  unloadCheckout: checkoutActions.unload,
  updatePaymentMethod: checkoutActions.updatePaymentMethod,
  saveAddress: addressActions.save
};

const mapStateToProps = (state, ownProps) => ({
  addresses: addressSelectors.getAddresses(state),
  provinces: addressSelectors.getProvinces(state),
  cart: cartSelectors.getFullCart(state),
  paymentMethods: checkoutSelectors.getPaymentMethods(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(withRouter(Payment))));
