// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Popconfirm, Button } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as listActions from 'redux/actions/list';
import * as orderActions from 'redux/actions/order';
import * as cartActions from 'redux/actions/cart';
import { Link } from 'react-router-dom';
import { toPrice, toDateString } from 'Utils/utils';
import { WrapperTable } from 'components';
import { withEventBus } from 'context';
import { CART_MINICART_LOADED } from 'redux/actions/cart/action_types';

const Fragment = React.Fragment;
const filters = [
  {
    field: 'code',
    type: 'TEXT',
    operator: 'EQUAL'
  },
  {
    field: 'shippingMethod',
    type: 'OPTION',
    options: [
      { id: 'STANDARD_SHIPPING', name: 'Standard Shipping' },
      { id: 'EXPEDITED_SHIPPING', name: 'Expedited Shipping' },
      { id: 'EXPRESS_SHIPPING', name: 'Express Shipping' }
    ]
  },
  {
    field: 'total',
    type: 'NUMBER'
  },
  {
    field: 'status',
    type: 'OPTION',
    options: [
      { id: 'PENDING', name: 'Pending' },
      { id: 'PROCESSING', name: 'Processing' },
      { id: 'PROCESSED', name: 'Processed' },
      { id: 'SHIPPED', name: 'Shipped' },
      { id: 'COMPLETED', name: 'Completed' },
      { id: 'CANCELLED', name: 'Cancelled' }
    ]
  }
];

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(CART_MINICART_LOADED, action => {
        this.props.history.push('/viewCart');
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  render() {
    const columns = [
      {
        title: 'Order Id',
        dataIndex: 'code',
        defaultSortOrder: 'descend',
        sorter: true
      },
      {
        title: 'Order Date',
        dataIndex: 'orderDateTime',
        sorter: true,
        render: d => toDateString(d)
      },
      {
        title: 'Name',
        dataIndex: 'customerFirstName',
        render: (customerFirstName, obj) => {
          return `${customerFirstName} ${obj.customerLastName}`;
        },
        sorter: true
      },
      {
        title: 'Shipping Method',
        dataIndex: 'shippingMethod',
        render: (shippingMethod, obj) => {
          if (shippingMethod != null) return `${shippingMethod.name}`;
        },
        sorter: true
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status, obj) => {
          return `${status.name}`;
        },
        sorter: true
      },
      {
        title: 'Order Total',
        dataIndex: 'total',
        render: amt => {
          return toPrice(amt);
        },
        sorter: true
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Fragment>
            <Link to={`/customer/my-account/order/${id}/`}>view</Link>
            {!this.props.showArchive && (
              <Popconfirm title="Do you want to add all products to cart?" onConfirm={() => this.props.reorder(id)}>
                <Button type="link">Re-order</Button>
              </Popconfirm>
            )}
          </Fragment>
        )
      }
    ];

    const defaultFilters = this.props.showArchive
      ? {
          archive: {
            _field: 'archive',
            operator: 'IS',
            value: 'archived'
          }
        }
      : {};

    return (
      <div>
        <Row style={{ marginBottom: '16px' }}>
          <Col span={24}>
            <WrapperTable
              title={this.props.showArchive ? 'Archived Order List' : 'Order List'}
              key={this.props.showArchive}
              filters={this.props.showFilters ? filters : []}
              defaultFilters={defaultFilters}
              columns={columns}
              listKey={this.props.listKey}
              data={this.props.listData}
              api={this.props.watchSearchOrders}
            />
          </Col>
        </Row>
        {!this.props.showArchive && (
          <Row>
            <Col span={24}>
              <span style={{ fontWeight: 'bold', marginTop: '64px' }}>
                If you want to view orders older than 6 months, please{' '}
                <Button type="primary" onClick={() => this.props.onClickShowArchive()}>
                  click here
                </Button>
              </span>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  watchSearchOrders: orderActions.watchSearchOrders,
  unload: listActions.listUnload,
  reorder: cartActions.reorder
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(OrderList));
