import { PHONE_BRANDS_LOADED, PHONES_LOADED, PHONES_UNLOAD } from 'redux/actions/attributes/action_types';

const initialStore = {
  phoneBrands: {
    loaded: false,
    list: []
  },
  phones: {
    loaded: false,
    list: []
  }
};

const phoneBrandsLoaded = (state, payload) =>
  Object.assign({}, state, {
    phoneBrands: {
      loaded: true,
      list: payload
    }
  });

const phonesLoaded = (state, payload) =>
  Object.assign({}, state, {
    phones: {
      loaded: true,
      list: payload
    }
  });

const unloadPhones = (state, payload) =>
  Object.assign({}, state, {
    phones: initialStore.phones
  });

export default function attributesReducer(state = initialStore, action) {
  switch (action.type) {
    case PHONE_BRANDS_LOADED:
      return phoneBrandsLoaded(state, action.payload);
    case PHONES_UNLOAD:
      return unloadPhones(state, action.payload);
    case PHONES_LOADED:
      return phonesLoaded(state, action.payload);
    default:
      return state;
  }
}
