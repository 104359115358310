import { PRODUCT_LOADING_STATUS } from 'redux/reducers/product';

export const getProducts = store => store.products;
export const areProductsLoading = store => getProducts(store).status !== PRODUCT_LOADING_STATUS.LOADED;
export const getProductsList = store => getProducts(store).list;
export const getActiveFilters = store => getProducts(store).activeFilters;
export const getFilters = store => getProducts(store).filters;
export const morePagesAvailable = store => getProducts(store).pageCount - getActiveFilters(store).page > 0;
export const getTitle = store => getProducts(store).title;
export const getSingleProduct = store => getProducts(store).singleProduct;
export const singleProductLoaded = store => getProducts(store).loadedSingleProduct;
export const getLatestPhones = store => getProducts(store);
