import React, { Component } from 'react';
import { NO_IMG_URL, NEW_IMG_URL, SPECIAL_PRICE_IMG_URLS, PRODUCT_TAG_URLS } from 'Utils/constants';
import { productStatusToStr } from 'Utils/utils';
import { PriceBox, ProductView } from 'components';
import { Button, Modal } from 'antd';
import styles from './product.module.less';

export default class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickViewOpen: false
    };
  }
  getLink = () =>
    '/product/' +
    this.props.product.productId +
    '/variant/' +
    this.props.product.id +
    '/?variant=' +
    this.props.product.variantNumber +
    (this.props.product.attributes.rack ? '&rack=' + this.props.product.attributes.rack : '');

  getImgUrl = p => (p.assets != null && p.assets.length > 0 ? p.assets[0].path : NO_IMG_URL);

  displayQuickView = () => {
    this.setState({ quickViewOpen: true });
    let p = this.props.product;
    this.props.loadSingleProduct(p.productId, p.id);
  };

  render() {
    let p = this.props.product;
    let stockClass = styles.stockStatus + ' ' + styles[p.stockStatus];
    return (
      <div className={styles.productContainer}>
        <div className={styles.boxImage}>
          {p.newTo && <img src={NEW_IMG_URL} alt="New" className={styles.newIcon} />}
          {p.specialPriceType && (
            <img
              src={SPECIAL_PRICE_IMG_URLS[p.specialPriceType]}
              alt={p.specialPriceType}
              className={styles.specialPriceIcon}
            />
          )}
          {!p.specialPriceType && p.tag && <img src={PRODUCT_TAG_URLS[p.tag]} alt={p.tag} className={styles.tag} />}
          <a href={this.getLink()} className={styles.imgLink} target="_blank" rel="noopener noreferrer" tabIndex="-1">
            <img src={this.getImgUrl(p)} alt={p.name} />
          </a>
          <Button onClick={this.displayQuickView} className={styles.quickViewBtn}>
            Quick View
          </Button>
        </div>
        <div className={styles.details}>
          <h2 className={styles.name}>
            <a href={this.getLink()} target="_blank" rel="noopener noreferrer" tabIndex="-1">
              {p.name}
            </a>
          </h2>
          <div className={styles.addInfo}>
            <div className={styles.infoContainer}>
              <div>SKU:</div>
              <div>{p.sku}</div>
            </div>
          </div>
          {this.props.showPrices && p.stockStatus === 'IN_STOCK' && (
            <PriceBox
              product={this.props.product}
              alreadyInCart={this.props.alreadyInCart}
              addToCart={this.props.addToCart}
              addToBag={this.props.addToBag}
              skipBag={this.props.skipBag}
              subject={this.props.subject}
            />
          )}
          {this.props.showPrices && p.stockStatus !== 'IN_STOCK' && (
            <div className={stockClass}>
              <p>{productStatusToStr(p.stockStatus)}</p>
            </div>
          )}
        </div>
        <Modal
          width="900px"
          afterClose={this.props.unloadSingleProduct}
          visible={this.state.quickViewOpen && this.props.singleProduct.product !== undefined}
          onCancel={() => this.setState({ quickViewOpen: false })}
          destroyOnClose={true}
          footer={null}
        >
          <ProductView
            product={this.props.singleProduct.product}
            variant={this.props.singleProduct.variant}
            addToCart={this.props.addToCart}
            addToBag={this.props.addToBag}
            showPrices={this.props.showPrices}
            alreadyInCart={this.props.alreadyInCart}
          />
        </Modal>
      </div>
    );
  }
}
