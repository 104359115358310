// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Tabs, Divider, Button, Modal } from 'antd';
import { DisplayField, AddressView, AddressForm } from 'components';
import { ResetPassword, InvoiceList, OrderList } from 'pages';
import * as customerActions from 'redux/actions/customer';
import * as addressActions from 'redux/actions/address';
import * as listActions from 'redux/actions/list';
import * as myAccountSelectors from 'redux/selectors/customer';
import * as addressSelectors from 'redux/selectors/address';
import { ADDRESS_SAVED, ADDRESS_DELETED } from 'redux/actions/address/action_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withEventBus } from 'context/eventbus';
import style from './account.home.module.less';
import { CART_MINICART_LOADED } from 'redux/actions/cart/action_types';

const { confirm } = Modal;

class AccountHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddressForm: false,
      selectedAddress: {},
      currentTab: '1',
      showArchive: false
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.tabChanged('1');
    this.subscriptions.push(
      this.props.eventBusSubscribe(ADDRESS_SAVED, () => {
        this.toggleAddressForm();
        this.props.listAddress();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(ADDRESS_DELETED, () => {
        this.props.listAddress();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(CART_MINICART_LOADED, action => {
        this.props.history.push('/viewCart');
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  saveNewAddress = add => {
    if (add.id === 0) this.props.saveAddress(add);
    else this.props.updateAddress(add);
  };

  tabChanged = (tab, showArchive = false) => {
    this.setState(
      {
        currentTab: tab,
        showArchive: showArchive
      },
      () => {
        switch (tab) {
          case '1':
            if (!this.state.isMyDetailsLoaded) {
              this.props.loadMyAccountDetails();
              this.setState({ isMyDetailsLoaded: true });
            }
            break;
          case '2':
          case '3':
            this.props.unload();
            this.props.reload();
            break;
          case '4':
            this.props.listAddress();
            break;
          case '5':
            break;
          default:
        }
      }
    );
  };

  editAddress = add => {
    this.setState(
      {
        selectedAddress: add
      },
      this.toggleAddressForm()
    );
  };

  deleteAddress = add => {
    confirm({
      title: 'Delete address',
      content: 'Are you sure you want to delete this address?',
      onOk: () => {
        this.props.deleteAdd(add);
      },
      onCancel() {}
    });
  };

  addNewAddress = add => {
    this.setState(
      {
        selectedAddress: { id: 0 }
      },
      this.toggleAddressForm()
    );
  };

  toggleAddressForm = () => {
    this.setState((state, props) => ({
      showAddressForm: !state.showAddressForm
    }));
  };

  render() {
    const { TabPane } = Tabs;
    let myDetails = this.props.customer;
    let status = myDetails.status ? myDetails.status.name : '';

    return (
      <div className="cuiContentWrapper">
        <div className={style.container}>
          <Row>
            <Col span={24} className={style.accountInfoButtonsContainer}>
              <Tabs
                defaultActiveKey="1"
                tabPosition="left"
                size="large"
                onTabClick={value => this.tabChanged(value, false)}
              >
                <TabPane tab="Account Details" key="1" className="{style.tab}">
                  <Row gutter={24}>
                    <Col span={8}>
                      <DisplayField label="Customer Id" value={myDetails.code} />
                    </Col>
                    <Col span={8}>
                      <DisplayField label="Email" value={myDetails.email} />
                    </Col>
                    <Col span={8}>
                      <DisplayField label="Status" value={status} />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <DisplayField label="First Name" value={myDetails.firstName} />
                    </Col>
                    <Col span={8}>
                      <DisplayField label="Middle Name" value={myDetails.middleName} />
                    </Col>
                    <Col span={8}>
                      <DisplayField label="Last name" value={myDetails.lastName} />
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={24}>
                    <Col span={10}>
                      <AddressView address={myDetails.defaultBillingAddress} title="Default Billing Address" />
                    </Col>
                    <Col span={12}>
                      <AddressView address={myDetails.defaultShippingAddress} title="Default Shipping Address" />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="My Orders" key="2" forceRender={true}>
                  {this.state.currentTab === '2' && (
                    <OrderList
                      listKey="ORDER_LIST"
                      showFilters={false}
                      showArchive={this.state.showArchive}
                      onClickShowArchive={() => this.tabChanged('2', true)}
                    />
                  )}
                </TabPane>
                <TabPane tab="My Invoices" key="3" forceRender={true}>
                  {this.state.currentTab === '3' && (
                    <InvoiceList
                      listKey="INVOICE_LIST"
                      showFilters={false}
                      showArchive={this.state.showArchive}
                      onClickShowArchive={() => this.tabChanged('3', true)}
                    />
                  )}
                </TabPane>
                <TabPane tab="My Address" key="4">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Button className={style.addNewAddBtn} type="primary" onClick={this.addNewAddress}>
                        Add New
                      </Button>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} />
                    {this.props.addresses.map(add => (
                      <Col span={8} key={add.id}>
                        <div className={style.addressViewContainer}>
                          <AddressView key={add.id} address={add} />
                          {(add.eShipperId === null || add.eShipperId === '0') && (
                            <Button size="small" className={style.editAddBtn} onClick={() => this.editAddress(add)}>
                              <FontAwesomeIcon icon="edit" /> Edit
                            </Button>
                          )}
                          <Button
                            type="danger"
                            size="small"
                            className={style.deleteBtn}
                            onClick={() => this.deleteAddress(add)}
                          >
                            <FontAwesomeIcon icon="trash" /> Delete
                          </Button>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Modal
                    title={this.state.selectedAddress.id !== 0 ? 'Update Address' : 'Add new address'}
                    centered
                    visible={this.state.showAddressForm}
                    footer={null}
                    onCancel={this.toggleAddressForm}
                    width="850px"
                    destroyOnClose={true}
                  >
                    <AddressForm
                      data={this.state.selectedAddress}
                      loadProvinces={this.props.loadProvinces}
                      provinces={this.props.provinces}
                      saveAddress={this.saveNewAddress}
                    />
                  </Modal>
                </TabPane>
                <TabPane tab="Reset Password" key="5">
                  <ResetPassword />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadMyAccountDetails: customerActions.loadMyAccountDetails,
  loadProvinces: addressActions.loadProvinces,
  unload: listActions.listUnload,
  reload: listActions.reload,
  listAddress: addressActions.load,
  saveAddress: addressActions.save,
  updateAddress: addressActions.update,
  deleteAdd: addressActions.deleteAdd
};

const mapStateToProps = (state, props) => {
  return {
    customer: myAccountSelectors.getCustomer(state),
    provinces: addressSelectors.getProvinces(state),
    isLoaded: myAccountSelectors.isLoaded(state),
    addresses: addressSelectors.getAddresses(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(withRouter(AccountHome)));
