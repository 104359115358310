export const getCategory = store => (store.category ? store.category : {});
export const getSubCategories = store => {
  let cat = getCategory(store);
  return cat.subCategories ? cat.subCategories : {};
};
export const areSubCategoriesLoaded = store => {
  let subCats = getSubCategories(store);
  return subCats.loaded != null && subCats.loaded === true;
};
export const getSubCategoriesList = store => (areSubCategoriesLoaded(store) ? getSubCategories(store).list : []);
