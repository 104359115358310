import React, { Component } from 'react';
import styles from './addressform.modules.less';

export default class AddressView extends Component {
  render() {
    return (
      <div className="addContainer">
        {this.props.address && (
          <div>
            {this.props.title && <span className="addressTitle">{this.props.title}</span>}
            <span className="addName">{this.props.address.name}</span>
            {this.props.address.company && <span className={styles.addLine}>{this.props.address.company}</span>}
            <span className="addLine">{this.props.address.street1}</span>
            {this.props.address.street2 && <span className="addLine">{this.props.address.street2}</span>}
            <span className="addLine">
              {this.props.address.city}, {this.props.address.province} {this.props.address.postalCode}
            </span>
            {this.props.address.phoneNo && <span className="addLine">{this.props.address.phoneNo}</span>}
          </div>
        )}
      </div>
    );
  }
}
