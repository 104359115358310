export const getAttributes = store => (store.attributes ? store.attributes : {});
export const getPhoneBrands = store => {
  let attrs = getAttributes(store);
  return attrs.phoneBrands ? attrs.phoneBrands : {};
};
export const arePhoneBrandsLoaded = store => {
  let phoneBrands = getPhoneBrands(store);
  return phoneBrands.loaded != null && phoneBrands.loaded;
};
export const getPhoneBrandsList = store => (arePhoneBrandsLoaded ? getPhoneBrands(store).list : []);

export const getPhones = store => {
  let attrs = getAttributes(store);
  return attrs.phones ? attrs.phones : {};
};
export const arePhonesLoaded = store => {
  let phones = getPhones(store);
  return phones.loaded != null && phones.loaded;
};
export const getPhonesList = store => (arePhonesLoaded ? getPhones(store).list : []);
