import configSagas from './config';
import homepageSagas from './homepage';
import attributesSagas from './attributes';
import categorySagas from './category';
import productSagas from './product';
import userSagas from './user';
import cartSagas from './cart';
import addressSagas from './address';
import checkoutSagas from './checkout';
import customerSages from './customer';
import orderSagas from './order';
import invoiceSagas from './invoice';
import pageSagas from './page';

export function run(sagaMiddleware) {
  sagaMiddleware.run(configSagas);
  sagaMiddleware.run(homepageSagas);
  sagaMiddleware.run(attributesSagas);
  sagaMiddleware.run(categorySagas);
  sagaMiddleware.run(productSagas);
  sagaMiddleware.run(userSagas);
  sagaMiddleware.run(cartSagas);
  sagaMiddleware.run(addressSagas);
  sagaMiddleware.run(checkoutSagas);
  sagaMiddleware.run(customerSages);
  sagaMiddleware.run(orderSagas);
  sagaMiddleware.run(invoiceSagas);
  sagaMiddleware.run(pageSagas);
}
