import { call, takeLatest, all, put } from 'redux-saga/effects';
import { PAGE_LOAD, PAGE_LOADED } from 'redux/actions/page/action_types';
import * as pageService from 'redux/services/page';

function* findOne() {
  yield takeLatest(PAGE_LOAD, function*(action) {
    let page = yield call(pageService.findByUrl, action.payload);
    yield put({
      type: PAGE_LOADED,
      payload: page
    });
  });
}

export default function* InvoiceSagas() {
  yield all([findOne()]);
}
