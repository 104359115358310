import {
  PRODUCTS_LOAD,
  PRODUCTS_LOADED,
  PRODUCTS_UNLOAD,
  PRODUCTS_UPDATE_FILTERS,
  PRODUCTS_FILTERS_LOADED,
  PRODUCTS_LOAD_NEXT_PAGE,
  PRODUCTS_PAGE_COUNT_LOADED,
  PRODUCTS_TOGGLE_FILTER,
  PRODUCTS_REFINE_BY_FILTERS,
  PRODUCTS_SINGLE_LOADED,
  PRODUCTS_UNLOAD_SINGLE_PRODUCT,
  PHONE_LATEST_LOADED,
} from 'redux/actions/product/action_types';

export const PRODUCT_LOADING_STATUS = Object.freeze({
  NOT_LOADED: 0,
  LOADING: 1,
  LOADED: 2,
});
export const PRODUCT_SORT_OPTIONS = Object.freeze({
  DEFAULT: 'Default',
  NEWEST_FIRST: 'Newest first',
  OLDEST_FIRST: 'Oldest First',
  LOWEST_PRICE: 'Lowest price first',
  HIGHEST_PRICE: 'Highest price first',
  NEW_ARRIVALS: 'New Arrivals',
});

const initialStore = {
  status: PRODUCT_LOADING_STATUS.NOT_LOADED,
  singleProduct: {},
  loadedSingleProduct: false,
  filters: {
    loaded: false,
    attributes: [],
    categories: [],
    phoneBrands: [],
    products: [],
    searchTerms: [],
  },
  activeFilters: {
    listBy: '',
    value: '',
    baseCategory: {},
    categories: [],
    attributes: [],
    phoneBrands: [],
    products: [],
    page: 1,
    sortBy: PRODUCT_SORT_OPTIONS.DEFAULT,
    searchTerms: [],
    onlyNew: false,
    onlySale: false,
  },
  list: [],
  title: '',
  pageCount: 1,
};

const productsLoaded = (state, payload) => {
  return Object.assign({}, state, {
    status: PRODUCT_LOADING_STATUS.LOADED,
    list: [...state.list, ...payload.products],
  });
};

const updateProductsFilters = (state, payload) => {
  return Object.assign({}, state, {
    activeFilters: Object.assign({}, state.activeFilters, payload, { page: 1 }),
    list: [],
  });
};

const productFiltersLoaded = (state, payload) => {
  return Object.assign({}, state, {
    filters: {
      loaded: true,
      attributes: payload.attrFilters,
      categories: payload.categories,
      phoneBrands: payload.phoneBrands,
      products: payload.products,
      searchTerms: payload.searchTerms,
    },
    title: payload.title,
    activeFilters: Object.assign({}, state.activeFilters, {
      onlyNew: payload.onlyNew,
      onlySale: payload.onlySale,
      sortBy: payload.onlyNew ? PRODUCT_SORT_OPTIONS.NEW_ARRIVALS : PRODUCT_SORT_OPTIONS.DEFAULT,
    }),
  });
};

const productsLoadNextPage = (state, payload) => {
  return Object.assign({}, state, {
    activeFilters: Object.assign({}, state.activeFilters, { page: state.activeFilters.page + 1 }),
  });
};

const unloadProducts = (state, payload) => Object.assign({}, state, initialStore);

const productsLoad = (state, payload) => Object.assign({}, state, { status: PRODUCT_LOADING_STATUS.LOADING });

const productsToggleFilter = (state, payload) => {
  switch (payload.name) {
    case 'categories':
      let activeCats = state.activeFilters.categories;
      return Object.assign({}, state, {
        activeFilters: Object.assign({}, state.activeFilters, { categories: toggleFilter(activeCats, payload.value) }),
      });
    case 'phoneBrands':
      let activePb = state.activeFilters.phoneBrands;
      return Object.assign({}, state, {
        activeFilters: Object.assign({}, state.activeFilters, { phoneBrands: toggleFilter(activePb, payload.value) }),
      });
    case 'products':
      let activeProducts = state.activeFilters.products;
      return Object.assign({}, state, {
        activeFilters: Object.assign({}, state.activeFilters, {
          products: toggleFilter(activeProducts, payload.value),
        }),
      });
    default:
      let attrClone = [...state.activeFilters.attributes];
      let attrIdx = attrClone.findIndex((f) => f.code === payload.name);
      let attr;
      if (attrIdx !== -1) {
        attr = Object.assign({}, attrClone[attrIdx]);
        attrClone.splice(attrIdx, 1);
      } else {
        attr = {
          code: payload.name,
          values: [],
        };
      }
      attr.values = toggleFilter(attr.values, payload.value);
      if (attr.values.length !== 0 || (attr.code === 'combo' && state.activeFilters.value === 'combo-offers')) {
        attrClone.push(attr);
      }
      return Object.assign({}, state, {
        activeFilters: Object.assign({}, state.activeFilters, { attributes: attrClone }),
      });
  }
};

const toggleFilter = (current, value) => {
  if (current == null) {
    return [value];
  } else if (current.includes(value)) {
    return current.filter((c) => c !== value);
  } else {
    return [value, ...current];
  }
};

const refineByFilters = (state, payload) => {
  return Object.assign({}, state, {
    pageCount: initialStore.pageCount,
    list: [],
    activeFilters: Object.assign({}, state.activeFilters, { page: 1 }),
  });
};

const oneProductLoaded = (state, payload) => {
  return Object.assign({}, state, {
    singleProduct: payload,
    loadedSingleProduct: true,
  });
};

export default function categoryReducer(state = initialStore, action) {
  switch (action.type) {
    case PRODUCTS_LOAD:
      return productsLoad(state, action.payload);
    case PRODUCTS_LOADED:
      return productsLoaded(state, action.payload);
    case PRODUCTS_UNLOAD:
      return unloadProducts(state, action.payload);
    case PRODUCTS_UPDATE_FILTERS:
      return updateProductsFilters(state, action.payload);
    case PRODUCTS_FILTERS_LOADED:
      return productFiltersLoaded(state, action.payload);
    case PRODUCTS_LOAD_NEXT_PAGE:
      return productsLoadNextPage(state, action.payload);
    case PRODUCTS_PAGE_COUNT_LOADED:
      return Object.assign({}, state, { pageCount: action.payload.pageCount });
    case PRODUCTS_TOGGLE_FILTER:
      return productsToggleFilter(state, action.payload);
    case PRODUCTS_REFINE_BY_FILTERS:
      return refineByFilters(state, action.payload);
    case PRODUCTS_SINGLE_LOADED:
      return oneProductLoaded(state, action.payload);
    case PRODUCTS_UNLOAD_SINGLE_PRODUCT:
      return Object.assign({}, state, { singleProduct: {}, loadedSingleProduct: false });
    case PHONE_LATEST_LOADED:
      return Object.assign({}, state.phones, action.payload);
    default:
      return state;
  }
}
