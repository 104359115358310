// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { CreateCustomerForm } from 'components';
import * as customerActions from 'redux/actions/customer';
import * as addressActions from 'redux/actions/address';
import * as addressSelectors from 'redux/selectors/address';
import { CUSTOMER_SUMBIT_OK } from 'redux/actions/customer/action_types';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import styles from './account.module.less';
import { withEventBus } from 'context';
import { withRouter } from 'react-router-dom';

class AccountCreate extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentWillMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(CUSTOMER_SUMBIT_OK, () => {
        this.openNotificationWithIcon(
          'success',
          'Thank you for registering yourself. We will verify your account details and get in touch with you soon.'
        );
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, payload => {
        console.log(payload);
      })
    );
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: 'Account Registration',
      description: message
    });
    this.props.history.push('/');
  };

  saveCustomer = e => {
    this.props.trySaveCustomer(e);
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    return (
      <div className="cuiContentWrapper">
        <div className={styles.page}>
          <Row gutter={24}>
            <Col span={24}>
              <CreateCustomerForm
                eventBusSubscribe={this.props.eventBusSubscribe}
                saveCustomer={this.saveCustomer}
                loadProvinces={this.props.loadProvinces}
                provinces={this.props.provinces}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadProvinces: addressActions.loadProvinces,
  trySaveCustomer: customerActions.trySaveCustomer
};

const mapStateToProps = (state, ownProps) => ({
  provinces: addressSelectors.getProvinces(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(withRouter(AccountCreate)));
