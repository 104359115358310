import {
  CART_MINICART_LOADED,
  CART_ADD_TO_BAG,
  CART_CLEAR_BAG,
  CART_LOADED,
  CART_UNLOAD,
  CART_IMAGE_LOADED
} from 'redux/actions/cart/action_types';

const initialStore = {
  miniCart: {
    cartId: '',
    itemCount: 0,
    total: 0.0,
    items: {}
  },
  bag: {},
  cart: {
    isLoaded: false,
    items: []
  },
  images: {}
};

const minicartLoaded = (state, payload) =>
  Object.assign({}, state, {
    miniCart: payload
  });

const addToBag = (state, payload) => {
  let bagItems = Object.assign({}, state.bag);
  if (payload.qty) {
    bagItems[payload.productVariantId] = payload.qty;
  } else {
    bagItems[payload.productVariantId] = 0;
  }
  return Object.assign({}, state, {
    bag: bagItems
  });
};

const clearBag = (state, payload) => Object.assign({}, state, { bag: {} });

const cartLoaded = (state, payload) =>
  Object.assign({}, state, { cart: Object.assign({}, payload, { isLoaded: true }) });

const cartUnload = (state, payload) => Object.assign({}, initialStore, { miniCart: state.miniCart });

const cartImageLoaded = (state, payload) =>
  Object.assign({}, state, { images: Object.assign({}, state.images, payload) });

export default function cartReducer(state = initialStore, action) {
  switch (action.type) {
    case CART_MINICART_LOADED:
      return minicartLoaded(state, action.payload);
    case CART_ADD_TO_BAG:
      return addToBag(state, action.payload);
    case CART_CLEAR_BAG:
      return clearBag(state, action.payload);
    case CART_LOADED:
      return cartLoaded(state, action.payload);
    case CART_UNLOAD:
      return cartUnload(state, action.payload);
    case CART_IMAGE_LOADED:
      return cartImageLoaded(state, action.payload);
    default:
      return state;
  }
}
